import {
  Button,
  FormControl,
  FormLabel,
  Spacer,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import pluralize from "pluralize";
import React, { useContext, useMemo } from "react";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  selectAccessToken,
  selectChains,
  selectOrgProperties,
  selectUserResources,
} from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import MultiSelectFormControl from "../../components/MultiSelectFormControl";
import { RESOURCES } from "../../constants/user-constants";
import MessageModalContext from "../../contexts/MessageModalContext";
import { Store } from "../../domain/Store";
import { putStoresBatch } from "../../services/api.service";

type Props = {
  stores?: Store[];
  onFinish: (stores: Store[]) => void;
  isDisabled?: boolean;
};

type FormType = { chain: string };

type FormProps = {
  values: FormType;
  setValues: React.Dispatch<React.SetStateAction<FormType>>;
};

function StoreBatchFrom({ values, setValues }: FormProps) {
  const chains = useAppSelector(selectChains);

  return (
    <VStack minH={300}>
      <FormControl>
        <FormLabel>Chain</FormLabel>
        <MultiSelectFormControl
          inputProps={{
            value: values.chain || "",
            onChange: (e) =>
              setValues((oldValues: any) => ({
                ...oldValues,
                chain: e.target.value,
              })),
          }}
          value={values.chain ? [values.chain] : []}
          onChange={(selection: string[]) => {
            setValues((oldValues: any) => ({
              ...oldValues,
              chain: selection[selection.length - 1],
            }));
          }}
          autocompleteProps={{
            options: chains,
            exclude: values.chain ? [values.chain] : [],
            focusInputOnSuggestionClick: false,
          }}
          formControlProps={undefined}
          isDisabled={undefined}
          disabledReason={undefined}
          getOptionValue={undefined}
          helperText={undefined}
          required={undefined}
        />
      </FormControl>
    </VStack>
  );
}

function StoreEditBatch({ stores = [], onFinish, isDisabled = false }: Props) {
  const userResources = useAppSelector(selectUserResources);
  const messageModalContext = useContext(MessageModalContext);
  const orgProperties = useAppSelector(selectOrgProperties);
  const accessToken = useAppSelector(selectAccessToken);
  const storeLabel = useMemo(
    () => orgProperties?.properties?.storeNameReplacement || "Store",
    []
  );

  const handleSave = async (
    i: number,
    dialogState: any,
    setDialogState: React.Dispatch<React.SetStateAction<FormType>>
  ) => {
    const { isLoading, ...changedAttributes } = dialogState || {};
    setDialogState((oldState: FormType) => ({
      ...(oldState || {}),
      isLoading: true,
    }));
    console.log("changedAttributes", changedAttributes);
    const { result } = await putStoresBatch(
      accessToken,
      stores.map((i) =>
        new Store({
          ...i,
          ...changedAttributes,
        }).buildForUpdate()
      )
    );

    setDialogState((oldState: any) => ({
      ...(oldState || {}),
      isLoading: false,
    }));
    toast.success(
      `Successfully edited ${pluralize(storeLabel, stores.length, true)}`
    );
    onFinish?.([]);
    messageModalContext.dismissModal(i);
  };

  return (
    <Button
      leftIcon={<FaEdit />}
      variant="link"
      isDisabled={
        isDisabled || !userResources?.includes(RESOURCES.STORES_UPDATE)
      }
      aria-label={"Store edit button"}
      onClick={() => {
        const initialValues = stores.reduce(
          (acc, store, index) => {
            return {
              chain:
                store.chain !== acc.chain && index > 0
                  ? undefined
                  : store.chain,
            };
          },
          { chain: undefined }
        );
        messageModalContext.showModal({
          title: `Edit ${pluralize(storeLabel, stores.length, true)}`,
          message: (dialogState, setDialogState) => (
            <StoreBatchFrom values={dialogState} setValues={setDialogState} />
          ),
          hideCloseButton: true,
          actions: [
            {
              label: "status",
              Render: (dialogState) =>
                dialogState.isLoading ? (
                  <Text flex={1} textAlign="center">
                    Editing {pluralize(storeLabel, stores.length, true)}
                    <Spinner size="sm" marginLeft={5} />
                  </Text>
                ) : (
                  <Text>
                    You are editing {pluralize(storeLabel, stores.length, true)}
                  </Text>
                ),
            },
            {
              label: "Spacer",
              Render: () => <Spacer />,
            },
            {
              label: "Cancel",
            },
            {
              label: "Confirm",
              props: { colorScheme: "blue", ml: 3 },
              preventDismiss: true,
              callback: (i, dialogState, setDialogState) =>
                handleSave(i, dialogState, setDialogState),
            },
          ],
          initialDialogState: initialValues,
        });
      }}
    >
      Edit
    </Button>
  );
}

export default StoreEditBatch;
