import {
  Badge,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  GridItem,
  HStack,
  IconButton,
  Image,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  SliderMark,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Font } from "@samuelmeuli/font-manager";
import FontPicker from "font-picker-react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { IntlProvider } from "react-intl";
// @ts-ignore
import { ThemeProvider } from "styled-components";
// @ts-ignore
import { RootContext } from "kepler.gl/dist/components/context";
// @ts-ignore
import { messages } from "kepler.gl/localization";
// @ts-ignore
import { themeLT } from "kepler.gl/styles";
// @ts-ignore
import { CloseIcon, CopyIcon, DeleteIcon } from "@chakra-ui/icons";
import ColorSelector from "kepler.gl/dist/components/side-panel/layer-panel/color-selector";
import {
  camelCase,
  cloneDeep,
  isObjectLike,
  kebabCase,
  lowerCase,
  map,
  merge,
  mixin,
  snakeCase,
  startCase,
  toPairs,
  toUpper,
  upperCase,
} from "lodash";
import MapboxClient from "mapbox";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FaCaretDown } from "react-icons/fa";
import { IoBrush, IoClose } from "react-icons/io5";
import {
  MdAddLocationAlt,
  MdDragIndicator,
  MdRestaurant,
} from "react-icons/md";
import { toast } from "react-toastify";
import { selectOrgProperties } from "../../app/appSlice";
import { useAppSelector } from "../../app/store";
import directions_preview from "../../assets/images/directions_preview.png";
import product_preview from "../../assets/images/product_preview.png";
import static_preview from "../../assets/images/static_preview.png";
import store_preview from "../../assets/images/store_preview.png";
import ColorPicker from "../../components/ColorPicker";
import { DathicFileDrop } from "../../components/DathicFileDrop";
import DraggableMapPin from "../../components/DraggableMapPin";
import DynamicInput from "../../components/DynamicInput";
import LocationSearchInput from "../../components/LocationSearchInput";
import MultiSelectFormControl from "../../components/MultiSelectFormControl";
import Slider from "../../components/Slider";
import StylesInputModal from "../../components/StylesInputModal";
import { AUTH_TOKENS } from "../../constants/default-settings";
import dathicChakraTheme from "../../theme/dathic_chakra_theme";
import { urlPattern } from "../../utils/compareUtils";
import { transformReverse } from "../../utils/cssTransform";
import FacebookImageUpload from "../data-integrations/facebook/FacebookImageUpload";
import { S3Params } from "../stores/UploadHooks";
import { AdditionalOnlineSource } from "./AdditionalSourcesForm";
import { QrCode } from "./QRCampaigns";
import "./store-locator-config.css";
import { StoreCardPreview } from "./StoreCardPreview";

var pluralize = require("pluralize");

let componentForStyleModal: any;

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function NavButtons({
  isDirty = false,
  handleClose,
  isLoading,
}: {
  isDirty: boolean;
  handleClose?: (isDirty?: boolean) => void;
  isLoading: boolean;
}) {
  const orgProperties = useAppSelector(selectOrgProperties);
  const storeLocatorConfigured = ["payed", "free_trial"].includes(
    orgProperties?.store_locator?.status || ""
  );
  return (
    <Flex
      flexDir="row"
      justifyContent="space-between"
      position="absolute"
      bottom={5}
      right={10}
      zIndex={1}
    >
      {!storeLocatorConfigured && <Button type="submit">Next</Button>}
      {storeLocatorConfigured && isDirty && (
        <>
          <Button onClick={() => handleClose && handleClose(isDirty)}>
            Cancel
          </Button>
          <Button type="submit" ml={3} isLoading={isLoading}>
            Apply
          </Button>
        </>
      )}
    </Flex>
  );
}

export type StoreLocatorConfiguration = {
  theme: any;
  mapTheme: "light" | "dark";
  status: string;
  free_trial_ends: string;
  titleCase: string;
  fontType: string;
  fontSize: string;
  searchFilters: string[];
  searchTypes: string[];
  shopify_app_charges: any[];
  s3Bucket: string;
  initialCoordinates: { latitude: number; longitude: number } | null;
  initialZoom: number;
  storeCardImage: string;
  storeCardStaticImage: string;
  storeCardDefaultImage: string;
  storeCardType: string;
  allowSuggestions: string;
  suggestForm: string;
  showBuyOnlineButton: string;
  notificationEmail: string;
  notificationPhone: string;
  notificationPrefs: string;
  minProductsForCategories: number;
  additionalSources: AdditionalOnlineSource[];
  UATrackingId: string;
  GAMeasurementId: string;
  hideDathicOnlineOptions: boolean;
  fallbackLocationToStores: boolean;
  initialCoordinatesFromIP: boolean;
  storeDetails: {
    showStoreImage: boolean;
    buyOnlineLink: boolean;
    contactInfo: boolean;
    directionsButton: boolean;
    showType: boolean;
    openInfo: boolean;
    productsAvailable: boolean;
    productsVisualization: string;
    onlyDathicReviews: string;
    showReviews: boolean;
    allowReviewsPublic: boolean;
    numberOfReviewsToShow: number;
    showProductReviews: boolean;
    showAddress: boolean;
    nameFormat: string;
    showDescription: boolean;
    additionalServices: { label: string; icon: string; id: number }[];
    qrCodes: QrCode[];
  };
  mapPins: { type: string; value: string; basedon_color?: any };
};

function AdditionalService({
  service,
  updateService,
  addService,
}: {
  service?: { label: string; icon: string };
  updateService?: Function;
  addService?: Function;
}) {
  const [labelTxt, setLabelTxt] = useState(service?.label || "");
  const [iconUrl, setIconUrl] = useState(service?.icon || "");

  useEffect(() => {
    if (service && updateService)
      updateService({ ...service, label: labelTxt, icon: iconUrl });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconUrl, labelTxt]);

  return (
    <VStack>
      <VStack rounded="md" shadow="lg" bg="white">
        <FacebookImageUpload
          image={iconUrl ? { fileUrl: iconUrl } : undefined}
          accept=".jpg, .png"
          width={16}
          height={16}
          cropType={undefined}
          onLoad={(fileType: string, file: File, result: any) =>
            setIconUrl(result)
          }
          swapButton={false}
          onDelete={() => setIconUrl("")}
          onSwap={undefined}
          video={undefined}
          onApply={undefined}
          formControlProps={{ w: 16, mb: 0, mt: 4 }}
        />
        <Editable
          defaultValue={labelTxt}
          value={labelTxt}
          onChange={setLabelTxt}
          placeholder="Service Name"
          textAlign="center"
          w={130}
        >
          <EditablePreview
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            width={130}
            fontSize={14}
          />
          <EditableInput />
        </Editable>
      </VStack>
      {!service && addService && (labelTxt || iconUrl) && (
        <Button
          onClick={() => {
            addService({ label: labelTxt, icon: iconUrl });
            setIconUrl("");
            setLabelTxt("");
          }}
        >
          Add Service
        </Button>
      )}
    </VStack>
  );
}

const ImageCard = ({
  imageUrl,
  onChange,
  s3Params,
}: {
  imageUrl?: string;
  onChange: (url?: string) => void;
  s3Params: Partial<S3Params>;
}) => {
  return (
    <VStack>
      {(!imageUrl || !urlPattern.test(imageUrl)) && (
        <DathicFileDrop
          shouldUpload
          s3Params={s3Params}
          onUrl={onChange}
          accept="image/*"
          maxImageWidth={600}
          containerProps={{ w: 48, h: 48 }}
          messageDirection="column"
        />
      )}
      {imageUrl && urlPattern.test(imageUrl) && (
        <Box rounded="md" shadow="lg" w={48} h={48} overflow="hidden">
          <Image src={imageUrl} w="12rem" h="12rem" objectFit="contain" />
        </Box>
      )}
      <HStack w={"100%"} justifyContent={"space-between"}>
        {!!imageUrl && (
          <IconButton
            variant="link"
            icon={<DeleteIcon />}
            onClick={() => onChange("")}
            aria-label={"Remove product image"}
          />
        )}
      </HStack>
    </VStack>
  );
};

function StoreLocatorConfig({
  handleClose,
  onSubmit,
  isLoading,
}: {
  handleClose?: (isDirty?: boolean) => void;
  onSubmit: (newConfig: StoreLocatorConfiguration) => void;
  isLoading: boolean;
}) {
  const orgProperties = useAppSelector(selectOrgProperties);
  const fontPicker = useRef<FontPicker>(null);
  const allFonts = fontPicker.current?.fontManager.getFonts();
  const currentConfig = orgProperties?.store_locator;
  const storeLabel = orgProperties?.properties?.storeNameReplacement || "Store";
  const zipcodeLabel =
    orgProperties?.properties?.zipcodeNameReplacement || "Zip Code";

  const { handleSubmit, watch, reset, setValue, register, formState } =
    useForm<StoreLocatorConfiguration>({
      defaultValues: merge(
        {
          theme: {
            // @ts-ignore
            palette: { primary: { main: dathicChakraTheme.colors.blue[900] } },
          },
          fontType: "Poppins",
          fontSize: "14px",
          titleCase: "start",
          searchFilters: [],
          searchTypes: [],
          storeCardImage: "",
          allowSuggestions: "",
          suggestForm: "",
          showBuyOnlineButton: "",
          initialCoordinatesFromIP: true,
          notificationEmail: "",
          minProductsForCategories: 5,
          additionalSources: [],
          initialCoordinates: {
            latitude: 29.617821,
            longitude: -81.70622,
          },
          initialZoom: 9,
          storeDetails: {
            onlyDathicReviews: "",
            allowReviewsPublic: false,
            productsAvailable: false,
            productsVisualization: "",
            additionalServices: [],
            qrCodes: [],
            nameFormat: "",
          },
        },
        currentConfig || {}
      ),
    });

  const [, setComponentForStyleModal] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [usingUA, setUsingUA] = useState(
    !!orgProperties?.store_locator?.UATrackingId
  );
  const partialForStyleModal = useRef<any>();
  const keplerRoot = useRef();
  // @ts-ignore
  const client = useMemo(() => new MapboxClient(AUTH_TOKENS.MAPBOX_TOKEN), []);

  const changes = watch();
  const defaultPalette = {
    name: "color.customPalette",
    type: "custom",
    category: "Custom",
    colors: changes?.mapPins?.basedon_color?.colors || [
      "#5A1846",
      "#900C3F",
      "#C70039",
      "#E3611C",
      "#F1920E",
      "#FFC300",
    ],
  };
  const [colorUI, setColorUI] = useState({
    customPalette: defaultPalette,
    colorRangeConfig: {
      type: "all",
      steps: 6,
      reversed: false,
      custom: false,
    },
  });

  const getTrackingId = () =>
    usingUA ? changes.UATrackingId : changes.GAMeasurementId;

  useEffect(() => {
    const latitude =
      changes?.initialCoordinates?.latitude ??
      orgProperties?.store_locator?.initialCoordinates?.latitude;
    const longitude =
      changes?.initialCoordinates?.longitude ??
      orgProperties?.store_locator?.initialCoordinates?.longitude;
    if (latitude && longitude) {
      client.geocodeReverse({ latitude, longitude }).then((response: any) => {
        const feature = response?.entity?.features?.find(
          (feat: any) => feat.place_type?.[0] === "place"
        );
        if (!response?.error && feature) {
          setSelectedLocation(feature.place_name);
        }
      });
    }
  }, [changes, client, orgProperties]);

  const renderStyleModal = (componentName: string) => {
    mixin({
      findDeep: (obj, predicate, path) =>
        map(toPairs(obj), ([k, v]) => {
          return (
            predicate(v, k, obj, path) ||
            (isObjectLike(v)
              ? // @ts-ignore
                findDeep(v, predicate, [...(path || []), k])
              : undefined)
          );
        }).filter((v) => v)?.[0],
    });
    let foundComponent: any, path;
    if (changes) {
      // @ts-ignore
      [foundComponent, path] = findDeep(
        changes || {},
        (value: any, indexOrKey: any, obj: any, path = ["root"]) => {
          if (isNaN(indexOrKey) && value === componentName) {
            return [obj, path];
          }
          if (indexOrKey === componentName) {
            return [value, path];
          }
          return undefined;
        }
      );
    }
    if (!foundComponent) {
      // @ts-ignore
      [foundComponent, path] = findDeep(
        orgProperties?.store_locator,
        (value: any, indexOrKey: any, obj: any, path = ["root"]) => {
          if (isNaN(indexOrKey) && value === componentName) {
            return [obj, path];
          }
          if (indexOrKey === componentName) {
            return [value, path];
          }
          return undefined;
        }
      );
    }
    if (!foundComponent) {
      toast.warn("component not found");
      return;
    }

    foundComponent = {
      [componentName]: !isObjectLike(foundComponent)
        ? { name: componentName }
        : cloneDeep(foundComponent),
    };
    const appendObj = (destination: any, source: any, key: any) => {
      destination[key] = source;
    };
    componentForStyleModal = foundComponent[componentName];
    let partial = {};
    let destination = partial;
    path.forEach((key: any, i: any) => {
      let source: any = {};
      if (i === path.length - 1) {
        source[componentName] = componentForStyleModal;
      }
      if (key !== "root") {
        appendObj(destination, source, key);
      } else {
        appendObj(destination, componentForStyleModal, componentName);
      }
      destination = source;
    });
    // @ts-ignore
    let foundStyle = findDeep(
      componentForStyleModal,
      (value: any, indexOrKey: any) =>
        indexOrKey === "style" ? value : undefined
    );
    partialForStyleModal.current = partial;
    transformReverse(JSON.stringify(foundStyle))
      .then((result) => {
        componentForStyleModal.cssStyle = result.css;
        setComponentForStyleModal(foundComponent);
      })
      .catch(() => {
        setComponentForStyleModal(foundComponent);
      });
  };

  const handleReorderServices = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      changes.storeDetails?.additionalServices,
      result.source.index,
      result.destination.index
    );

    setValue("storeDetails.additionalServices", items, { shouldDirty: true });
  };

  const optionsForDynamicInput = [
    "county",
    "city",
    "state",
    "zipcode",
    "address",
    "chain",
    "city",
    "name",
    "phone",
    "short_address",
  ].reduce((acc, key) => ({ ...acc, [key]: `{${key}}` }), {});

  const selectedSecondaryColor = changes.theme?.palette?.secondary?.main;
  const selectedPrimaryColor = changes.theme?.palette?.primary?.main;

  const FilterTypesControl = () => {
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const types = ["restaurant", "popup_store"];
    const getIcon = (type: string) => {
      switch (type) {
        case "popup_store":
          return <MdAddLocationAlt size={36} />;
        default:
          return <MdRestaurant size={36} />;
      }
    };
    return (
      <HStack w="100%" justifyContent="center" spacing={5}>
        {types
          .filter((type) => changes.searchTypes?.includes(type))
          .map((type) => (
            <VStack
              spacing={0}
              onClick={
                selectedTypes.includes(type)
                  ? undefined
                  : () => setSelectedTypes((oldTypes) => [...oldTypes, type])
              }
              cursor="pointer"
            >
              <Box
                rounded="full"
                p={1}
                border={`2px solid ${
                  selectedTypes.includes(type) ? "white" : "transparent"
                }`}
                pos="relative"
              >
                {selectedTypes.includes(type) && (
                  <IconButton
                    aria-label="remove type filter"
                    bg="white"
                    rounded="full"
                    pos="absolute"
                    top={-1}
                    right={-1}
                    size="xs"
                    icon={<CloseIcon />}
                    onClick={() =>
                      setSelectedTypes((oldTypes) =>
                        oldTypes.filter((t) => t !== type)
                      )
                    }
                  />
                )}
                <Box rounded="full" bg={selectedSecondaryColor} p={2}>
                  {getIcon(type)}
                </Box>
              </Box>
              <Text>{startCase(type)}</Text>
            </VStack>
          ))}
      </HStack>
    );
  };

  return (
    <form onSubmit={handleSubmit((newConfig) => onSubmit(newConfig))}>
      <VStack
        spacing={10}
        divider={<Divider borderColor="gray.900" />}
        position="relative"
        pb={20}
        padding="padding: 0 5rem"
        textAlign={"start"}
      >
        {componentForStyleModal && (
          <StylesInputModal
            isOpen={!!componentForStyleModal}
            onClose={() => {
              componentForStyleModal = undefined;
              partialForStyleModal.current = undefined;
              setComponentForStyleModal(undefined);
            }}
            initialInput={componentForStyleModal.cssStyle}
            onOutputChange={(output: any) => {
              try {
                componentForStyleModal.style = JSON.parse(output);
                const newChanges = cloneDeep(currentConfig);
                reset(merge(newChanges, partialForStyleModal.current));
              } catch (error) {
                console.log(error);
              }
            }}
          />
        )}
        <FormControl display="flex" justifyContent="space-between" pr={15}>
          <FormLabel fontSize="18px" fontWeight="bold">
            Select the font type and text size
          </FormLabel>
          <VStack>
            <HStack alignItems="flex-start">
              <MultiSelectFormControl
                formControlProps={undefined}
                autocompleteProps={{
                  options: allFonts?.size ? Array.from(allFonts.values()) : [],
                }}
                isDisabled={undefined}
                disabledReason={undefined}
                value={[changes.fontType]}
                onChange={(selection) => {
                  setValue("fontType", selection.pop()?.family || "Poppins", {
                    shouldDirty: true,
                  });
                }}
                getOptionValue={(option: Font) => option.family}
                inputProps={{ placeholder: "Choose a font" }}
                helperText={undefined}
                required={undefined}
              />
              <FontPicker
                ref={fontPicker}
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ""}
                activeFontFamily={changes.fontType}
                limit={2000}
              />
              <Select {...register("fontSize")}>
                <option value="12px">Small</option>
                <option value="14px">Normal</option>
                <option value="18px">Big</option>
              </Select>
              <label>
                <Select {...register("titleCase")} w={150} mb={3}>
                  <option value="">None</option>
                  <option value="lower">{lowerCase("lower case")}</option>
                  <option value="start">{startCase("start case")}</option>
                  <option value="upper">{upperCase("upper case")}</option>
                  <option value="upper">{kebabCase("kebab case")}</option>
                  <option value="snake">{snakeCase("snake case")}</option>
                  <option value="upper">{camelCase("camel case")}</option>
                </Select>
                <FormHelperText>Text case for titles</FormHelperText>
              </label>
            </HStack>
            <Text className="apply-font" fontSize={changes.fontSize}>
              This is how your text will look
            </Text>
          </VStack>
        </FormControl>
        <HStack w="100%" alignItems="flex-start">
          <FormControl>
            <FormLabel fontSize="18px" fontWeight="bold">
              Select the colors to be used.
            </FormLabel>
            <FormHelperText>
              The main colors for the theme of your {storeLabel} Locator
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormLabel fontSize="18px" fontWeight="bold">
              Panel Color
            </FormLabel>
            <FormHelperText>
              Color for background of search panel and main buttons
            </FormHelperText>
            <Popover>
              {/*
            // @ts-ignore */}
              <PopoverTrigger>
                <Button
                  borderLeft={`34px solid ${selectedPrimaryColor} !important`}
                >
                  {selectedPrimaryColor}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverBody>
                  <ColorPicker
                    color={selectedPrimaryColor}
                    setColor={(v: string) => {
                      const oldObject = cloneDeep(changes.theme || {});
                      merge(oldObject, { palette: { primary: { main: v } } });
                      setValue("theme", oldObject, { shouldDirty: true });
                    }}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </FormControl>
          <FormControl>
            <FormLabel fontSize="18px" fontWeight="bold">
              Text Color
            </FormLabel>
            <FormHelperText>
              Color for secondary buttons and text
            </FormHelperText>
            <HStack>
              <Popover>
                {/*
            // @ts-ignore */}
                <PopoverTrigger>
                  <Button
                    borderLeft={`34px solid ${selectedSecondaryColor} !important`}
                  >
                    {selectedSecondaryColor}
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody>
                    <ColorPicker
                      color={selectedSecondaryColor}
                      setColor={(v: string) => {
                        const oldObject = cloneDeep(changes.theme || {});
                        merge(oldObject, {
                          palette: { secondary: { main: v } },
                        });
                        setValue("theme", oldObject, { shouldDirty: true });
                      }}
                    />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
              {changes.theme?.palette?.secondary && (
                <IconButton
                  icon={<CloseIcon />}
                  aria-label={"Close secondary color"}
                  onClick={() => {
                    const oldObject = cloneDeep(changes.theme || {});
                    delete oldObject.palette?.secondary;
                    setValue("theme", oldObject, { shouldDirty: true });
                  }}
                />
              )}
            </HStack>
          </FormControl>
        </HStack>
        <HStack w="100%">
          <VStack spacing={5} flex={1}>
            <FormControl>
              <FormLabel fontSize="18px" fontWeight="bold">
                Select filter options.
              </FormLabel>
              <FormHelperText>
                What filters will customers see under the search bar?
              </FormHelperText>
              <CheckboxGroup value={changes.searchFilters}>
                <HStack>
                  <Checkbox {...register("searchFilters")} value="Product">
                    By product
                  </Checkbox>
                  <Checkbox {...register("searchFilters")} value="Chain">
                    By chain
                  </Checkbox>
                </HStack>
              </CheckboxGroup>
            </FormControl>
            {changes.searchFilters?.includes("Product") && (
              <FormControl>
                <FormHelperText>
                  Starting from what number of products should the category
                  filter be shown?
                </FormHelperText>
                <Input
                  type="number"
                  {...register("minProductsForCategories", {
                    valueAsNumber: true,
                  })}
                  min={0}
                  max={100}
                  w={40}
                  placeholder="Default is 5"
                />
              </FormControl>
            )}
            <FormControl>
              <FormHelperText>
                What {storeLabel} types can be filtered?
              </FormHelperText>
              <CheckboxGroup value={changes.searchTypes}>
                <HStack>
                  <Checkbox {...register("searchTypes")} value="restaurant">
                    By restaurants
                  </Checkbox>
                  <Checkbox {...register("searchTypes")} value="popup_store">
                    By pop-up {pluralize(storeLabel)}
                  </Checkbox>
                </HStack>
              </CheckboxGroup>
            </FormControl>
          </VStack>
          <VStack w="27rem" bg={selectedPrimaryColor} p={3} rounded="lg">
            <Box w="100%" bg="white" rounded="lg" color="gray.900" p={3}>
              Search by Address or {zipcodeLabel}
            </Box>
            <HStack w="100%">
              {changes.searchFilters.includes("Product") && (
                <Flex
                  flex={1}
                  justifyContent="space-between"
                  alignContent="center"
                  color="gray.900"
                  p="0.45rem 0.75rem"
                  rounded="lg"
                  bg="white"
                >
                  <Text>Product</Text>
                  <FaCaretDown
                    style={{
                      color: selectedPrimaryColor,
                    }}
                  />
                </Flex>
              )}
              {changes.searchFilters.includes("Type") && (
                <Flex
                  flex={1}
                  justifyContent="space-between"
                  alignContent="center"
                  color="gray.900"
                  p="0.45rem 0.75rem"
                  rounded="lg"
                  bg="white"
                >
                  <Text>{storeLabel} Type</Text>
                  <FaCaretDown
                    style={{
                      color: selectedPrimaryColor,
                    }}
                  />
                </Flex>
              )}
              {changes.searchFilters.includes("Chain") && (
                <Flex
                  flex={1}
                  justifyContent="space-between"
                  alignContent="center"
                  color="gray.900"
                  p="0.45rem 0.75rem"
                  rounded="lg"
                  bg="white"
                >
                  <Text>Chain</Text>
                  <FaCaretDown
                    style={{
                      color: selectedPrimaryColor,
                    }}
                  />
                </Flex>
              )}
            </HStack>
            <Tabs w="100%" isFitted color="white">
              <TabList
                sx={{
                  '.chakra-tabs__tab[aria-selected="true"]': {
                    color: selectedSecondaryColor,
                  },
                }}
              >
                <Tab>BUY IN STORE</Tab>
                {changes.showBuyOnlineButton && <Tab>BUY ONLINE</Tab>}
              </TabList>
              <TabPanels>
                <TabPanel>
                  <FilterTypesControl />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </HStack>
        <FormControl isRequired display="flex" justifyContent="space-between">
          <VStack alignItems="flex-start">
            <FormLabel fontSize="18px" fontWeight="bold">
              Would you like to show the buy online options?
            </FormLabel>
            <FormHelperText>
              Dathic will search the web for your products and show the online
              options for your products. You can manually add e-commerce
              handling your product in the Stores tab.
            </FormHelperText>
          </VStack>
          <RadioGroup value={changes.showBuyOnlineButton}>
            <HStack mr={15} alignItems="flex-start">
              <Radio {...register("showBuyOnlineButton")} value="true">
                Yes
              </Radio>
              <Radio {...register("showBuyOnlineButton")} value="">
                No
              </Radio>
            </HStack>
          </RadioGroup>
        </FormControl>
        <FormControl isRequired>
          <FormLabel fontSize="18px" fontWeight="bold">
            Select the visual of the store pin on the map
          </FormLabel>
          <HStack spacing={10} w="100%">
            <RadioGroup
              flex={1}
              value={changes.mapPins?.type}
              onChange={() =>
                setValue("mapPins.value", "", { shouldDirty: true })
              }
            >
              <VStack alignItems="flex-start" spacing={10}>
                <HStack>
                  <Radio {...register("mapPins.type")} value="color">
                    Color
                  </Radio>
                  <Popover isLazy>
                    {changes.mapPins?.type === "color" && (
                      // @ts-ignore
                      <PopoverTrigger>
                        <Button
                          borderLeft={`34px solid ${changes?.mapPins?.value} !important`}
                        >
                          {changes?.mapPins?.type === "color"
                            ? changes?.mapPins?.value
                            : "Select Color"}
                        </Button>
                      </PopoverTrigger>
                    )}
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverBody>
                        <ColorPicker
                          color={changes?.mapPins?.value}
                          setColor={(v: string) => {
                            setValue(
                              "mapPins",
                              { type: "color", value: v },
                              { shouldDirty: true }
                            );
                          }}
                        />
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </HStack>
                <HStack>
                  <Radio {...register("mapPins.type")} value="icon">
                    Icon
                  </Radio>
                  {changes.mapPins?.type === "icon" && (
                    <DathicFileDrop
                      maxImageWidth={50}
                      shouldUpload
                      s3Params={{
                        getFileName: (file) =>
                          `mapPins_${orgProperties?.organization?.id}_`.concat(
                            new Date().getTime()
                          ),
                        isPublic: true,
                        folderName: "store-locator-icons",
                      }}
                      onUrl={(url) => {
                        setValue(
                          "mapPins",
                          { type: "icon", value: url || "" },
                          { shouldDirty: true }
                        );
                      }}
                      accept="image/*"
                      message="Choose icon for pin"
                    />
                  )}
                </HStack>
                <HStack>
                  <Radio {...register("mapPins.type")} value="basedon">
                    Based on {storeLabel} attribute
                  </Radio>
                  {changes.mapPins?.type === "basedon" && (
                    <VStack>
                      <Select {...register("mapPins.value")}>
                        <option value="chain">chain</option>
                        <option value="type">type</option>
                        <option value="is_open">is open</option>
                        <option value="avg_rating">avg rating</option>
                        <option value="num_reviews">number of reviews</option>
                      </Select>
                      <RootContext.Provider value={keplerRoot}>
                        {/*
// @ts-ignore */}
                        <IntlProvider locale={"en"} messages={messages["en"]}>
                          {/*
// @ts-ignore */}
                          <Box w="100%" ref={keplerRoot}>
                            <ThemeProvider theme={themeLT}>
                              <ColorSelector
                                colorSets={[
                                  {
                                    selectedColor:
                                      changes?.mapPins?.basedon_color ||
                                      defaultPalette,
                                    setColor: (v: any) => {
                                      setValue("mapPins.basedon_color", v, {
                                        shouldDirty: true,
                                      });
                                    },
                                    isRange: true,
                                  },
                                ]}
                                colorUI={colorUI}
                                setColorUI={(changedUI: any) => {
                                  setColorUI((oldUI) => {
                                    const newUI = { ...(oldUI || {}) };
                                    merge(newUI, changedUI);
                                    if (changedUI?.customPalette?.colors) {
                                      newUI.customPalette.colors =
                                        changedUI.customPalette.colors;
                                    }
                                    return newUI;
                                  });
                                }}
                              />
                            </ThemeProvider>
                          </Box>
                        </IntlProvider>
                      </RootContext.Provider>
                    </VStack>
                  )}
                </HStack>
              </VStack>
            </RadioGroup>
            <DraggableMapPin
              coordinates={changes.initialCoordinates || undefined}
              zoom={changes.initialZoom}
              draggable={false}
              scrollZoom={false}
              containerProps={{ w: "27rem" }}
              markerPin={
                !!(
                  changes?.mapPins?.type === "color" && changes?.mapPins?.value
                ) ? (
                  <Box
                    w={3}
                    h={3}
                    rounded="full"
                    shadow="base"
                    bg={changes?.mapPins?.value}
                  />
                ) : !!(
                    changes?.mapPins?.type === "icon" && changes?.mapPins?.value
                  ) ? (
                  <Image
                    src={changes?.mapPins?.value}
                    alt="map_preview"
                    w={5}
                    h={5}
                  />
                ) : (
                  <></>
                )
              }
              theme={changes.mapTheme}
              onChangeTheme={(newTheme) => setValue("mapTheme", newTheme)}
            />
          </HStack>
        </FormControl>
        <FormControl>
          <FormLabel fontSize="18px" fontWeight="bold">
            Select a card style.
          </FormLabel>
          <FormHelperText>
            What {storeLabel} information will customers see in the map preview?
          </FormHelperText>
          <RadioGroup value={changes.storeCardImage}>
            <HStack alignItems="flex-start" justifyContent="space-evenly">
              <HStack>
                <VStack>
                  <Box pos="relative">
                    <Image src={static_preview} alt="static_preview" h={120} />
                  </Box>
                  <Radio
                    isChecked={changes.storeCardImage === "noimage"}
                    onChange={() =>
                      setValue("storeCardImage", "noimage", {
                        shouldDirty: true,
                      })
                    }
                  >
                    No Image
                  </Radio>
                </VStack>
                <VStack>
                  <Box pos="relative">
                    {changes.storeCardImage === "static" &&
                      !!changes?.storeCardStaticImage && (
                        <Image
                          src={changes?.storeCardStaticImage}
                          alt="card_static_image"
                          w="100px"
                          h="100%"
                          pos="absolute"
                          right="7px"
                          p="8px 0"
                          objectFit="contain"
                        />
                      )}
                    {changes.storeCardImage === "store" ? (
                      <Image src={store_preview} alt="store_preview" h={120} />
                    ) : changes.storeCardImage === "product" ? (
                      <Image
                        src={product_preview}
                        alt="product_preview"
                        h={120}
                      />
                    ) : (
                      <Image
                        src={static_preview}
                        alt="static_preview"
                        h={120}
                      />
                    )}
                  </Box>
                  <Radio
                    isChecked={!!changes.storeCardImage}
                    onChange={() =>
                      setValue("storeCardImage", "store", { shouldDirty: true })
                    }
                  >
                    Image Preview
                  </Radio>
                </VStack>
                <VStack>
                  <Select
                    value={changes.storeCardImage}
                    onChange={(e) =>
                      setValue("storeCardImage", e.target.value, {
                        shouldDirty: true,
                      })
                    }
                  >
                    <option value="store">Store</option>
                    <option value="product">Product</option>
                    <option value="static">Static image</option>
                  </Select>
                  {changes.storeCardImage === "static" && (
                    <DathicFileDrop
                      maxImageWidth={250}
                      shouldUpload
                      s3Params={{
                        getFileName: (file) =>
                          `static_${orgProperties?.organization?.id}_`.concat(
                            new Date().getTime()
                          ),
                        isPublic: true,
                        folderName: "store-locator-icons",
                      }}
                      onUrl={(url) => {
                        setValue("storeCardStaticImage", url || "", {
                          shouldDirty: true,
                        });
                      }}
                      accept="image/*"
                      message="Choose static image"
                    />
                  )}
                </VStack>
              </HStack>
              <VStack>
                <Image
                  src={directions_preview}
                  alt="directions_preview"
                  h={120}
                />
                <Radio {...register("storeCardImage")} value="">
                  Directions Preview
                </Radio>
              </VStack>
            </HStack>
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel fontSize="18px" fontWeight="bold">
            Choose what information is displayed on a featured {storeLabel}{" "}
            card.
          </FormLabel>
          <FormHelperText>
            What additional information will customers see once they click on a
            specific {storeLabel}?
          </FormHelperText>
          <CheckboxGroup>
            <HStack w="100%" alignItems="flex-start">
              <SimpleGrid columns={2} gap={6}>
                <Checkbox {...register("storeDetails.showStoreImage")}>
                  {storeLabel} Image
                </Checkbox>
                <Checkbox {...register("storeDetails.buyOnlineLink")}>
                  Link to {storeLabel} website
                </Checkbox>
                <Checkbox {...register("storeDetails.contactInfo")}>
                  {storeLabel} Contact Info
                </Checkbox>
                <Checkbox {...register("storeDetails.directionsButton")}>
                  Directions to {storeLabel}{" "}
                  {!!changes.storeDetails?.directionsButton && (
                    <IconButton
                      aria-label="direction-style-button"
                      icon={<IoBrush />}
                      onClick={() => renderStyleModal("directionsButton")}
                    />
                  )}
                </Checkbox>
                <Checkbox {...register("storeDetails.showType")}>
                  Display {storeLabel} Type
                </Checkbox>
                <Checkbox {...register("storeDetails.openInfo")}>
                  {storeLabel} Status
                </Checkbox>
                <GridItem>
                  <Checkbox {...register("storeDetails.productsAvailable")}>
                    In-{storeLabel} Product Availability
                  </Checkbox>
                  <FormControl isRequired>
                    <RadioGroup
                      ml={10}
                      value={changes.storeDetails?.productsVisualization}
                      isDisabled={!changes.storeDetails?.productsAvailable}
                    >
                      <VStack alignItems="flex-start">
                        <Radio
                          {...register("storeDetails.productsVisualization")}
                          value="grid"
                        >
                          Display as grid
                        </Radio>
                        <Radio
                          {...register("storeDetails.productsVisualization")}
                          value=""
                        >
                          Display as carousel
                        </Radio>
                      </VStack>
                    </RadioGroup>
                  </FormControl>
                </GridItem>
                <GridItem rowSpan={2}>
                  <Checkbox {...register("storeDetails.showReviews")}>
                    {storeLabel} Reviews
                  </Checkbox>
                  <VStack p="0 2rem" divider={<Divider />}>
                    <RadioGroup
                      value={changes.storeDetails?.onlyDathicReviews}
                      isDisabled={!changes.storeDetails?.showReviews}
                    >
                      <HStack alignItems="flex-start">
                        <Radio
                          {...register("storeDetails.onlyDathicReviews")}
                          value=""
                        >
                          All review sources
                        </Radio>
                        <Radio
                          {...register("storeDetails.onlyDathicReviews")}
                          value="only_dathic_reviews"
                        >
                          Only Dathic reviews
                        </Radio>
                      </HStack>
                    </RadioGroup>
                    <RadioGroup
                      value={
                        changes.storeDetails?.allowReviewsPublic ? "true" : ""
                      }
                      isDisabled={!changes.storeDetails?.showReviews}
                    >
                      <HStack alignItems="flex-start">
                        <Radio
                          {...register("storeDetails.allowReviewsPublic")}
                          value=""
                        >
                          Do not allow reviews
                        </Radio>
                        <Radio
                          {...register("storeDetails.allowReviewsPublic")}
                          value="true"
                        >
                          Allow public reviews
                        </Radio>
                      </HStack>
                    </RadioGroup>
                    <HStack alignItems="flex-start">
                      <VStack>
                        <Text>
                          Display max{" "}
                          <Input
                            type="number"
                            {...register("storeDetails.numberOfReviewsToShow", {
                              valueAsNumber: true,
                            })}
                            rounded="md"
                            min={0}
                            max={100}
                            w={10}
                            isDisabled={!changes.storeDetails?.showReviews}
                          />{" "}
                          reviews
                        </Text>
                      </VStack>
                      <Radio
                        onClick={() =>
                          setValue(
                            "storeDetails.numberOfReviewsToShow",
                            Number.NaN,
                            { shouldDirty: true }
                          )
                        }
                        isChecked={isNaN(
                          changes.storeDetails?.numberOfReviewsToShow
                        )}
                      >
                        Display infinite reviews
                      </Radio>
                    </HStack>
                  </VStack>
                </GridItem>
                <Checkbox
                  {...register("storeDetails.showProductReviews")}
                  isDisabled={!changes.storeDetails?.productsAvailable}
                >
                  Product Reviews
                </Checkbox>
                <Checkbox {...register("storeDetails.showAddress")}>
                  {storeLabel} Address
                </Checkbox>
                <GridItem rowSpan={3}>
                  <FormControl>
                    <FormLabel>
                      Default image for no product availability
                    </FormLabel>
                    <FormHelperText>
                      This image will be used when no product is available in
                      the store
                    </FormHelperText>
                    <ImageCard
                      imageUrl={changes.storeCardDefaultImage}
                      s3Params={{
                        getFileName: (file) =>
                          `default_image_${orgProperties?.organization?.id}_`.concat(
                            `${new Date().getTime()}`
                          ),
                        isPublic: true,
                        folderName: "store-locator-icons",
                      }}
                      onChange={(url) => {
                        setValue("storeCardDefaultImage", url || "", {
                          shouldDirty: true,
                        });
                      }}
                    />
                  </FormControl>
                </GridItem>
                <Checkbox {...register("storeDetails.showDescription")}>
                  {storeLabel} Description
                </Checkbox>
                <GridItem colSpan={1}>
                  <FormControl>
                    <FormLabel>Additional Services</FormLabel>
                    <FormHelperText>
                      Add services, offers or content offered in ALL locations
                    </FormHelperText>
                    <HStack h={36} alignItems="flex-start">
                      {changes.storeDetails?.additionalServices?.length && (
                        <DragDropContext onDragEnd={handleReorderServices}>
                          <Droppable
                            droppableId="additionalServicesList"
                            direction="horizontal"
                          >
                            {(provided) => (
                              <HStack
                                ref={provided.innerRef}
                                flex={1}
                                h="100%"
                                bg="whitesmoke"
                                shadow="inner"
                                spacing={3}
                                p="0 10px"
                                overflowX="auto"
                                {...provided.droppableProps}
                              >
                                {changes.storeDetails?.additionalServices?.map(
                                  (s, index) => (
                                    <Draggable
                                      key={s.id}
                                      draggableId={`${s.id}`}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <Box
                                          pos="relative"
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                        >
                                          <IconButton
                                            aria-label="delete additional service button"
                                            position="absolute"
                                            variant="link"
                                            top={0}
                                            right={0}
                                            size="xs"
                                            icon={<IoClose />}
                                            onClick={() => {
                                              const newServices = [
                                                ...(changes.storeDetails
                                                  .additionalServices || []),
                                              ];
                                              newServices.splice(index, 1);
                                              setValue(
                                                "storeDetails.additionalServices",
                                                newServices,
                                                { shouldDirty: true }
                                              );
                                            }}
                                          />
                                          <Box
                                            pos="absolute"
                                            top={0}
                                            left={0}
                                            {...provided.dragHandleProps}
                                          >
                                            <MdDragIndicator size={24} />
                                          </Box>
                                          <AdditionalService
                                            service={s}
                                            updateService={(newService: {
                                              label: string;
                                              icon: string;
                                              id: number;
                                            }) => {
                                              const newServices = [
                                                ...(changes.storeDetails
                                                  .additionalServices || []),
                                              ];
                                              newServices.splice(
                                                index,
                                                1,
                                                newService
                                              );
                                              setValue(
                                                "storeDetails.additionalServices",
                                                newServices,
                                                { shouldDirty: true }
                                              );
                                            }}
                                          />
                                        </Box>
                                      )}
                                    </Draggable>
                                  )
                                )}
                                {provided.placeholder}
                              </HStack>
                            )}
                          </Droppable>
                        </DragDropContext>
                      )}
                      <AdditionalService
                        addService={(newService: {
                          label: string;
                          icon: string;
                        }) =>
                          setValue(
                            "storeDetails.additionalServices",
                            [
                              ...(changes.storeDetails?.additionalServices ||
                                []),
                              {
                                ...newService,
                                id: (
                                  changes.storeDetails?.additionalServices || []
                                ).length,
                              },
                            ],
                            { shouldDirty: true }
                          )
                        }
                      />
                    </HStack>
                  </FormControl>
                </GridItem>
              </SimpleGrid>
              <VStack maxW="27rem">
                <FormControl>
                  <FormLabel>{storeLabel} Name</FormLabel>
                  <DynamicInput
                    data={optionsForDynamicInput}
                    isTextArea
                    info={
                      <Box>
                        <strong>
                          Customizing Visible Name of {storeLabel}:
                        </strong>
                        <p>{`To customize the text used to display the ${storeLabel} name select the corresponding variable.`}</p>
                        <Text
                          fontSize={12}
                        >{`(ie: "{chain}, {zipcode}" would appear as "Walmart, 29615")`}</Text>
                      </Box>
                    }
                    value={changes.storeDetails.nameFormat}
                    onChange={(newValue) =>
                      setValue("storeDetails.nameFormat", newValue, {
                        shouldDirty: true,
                      })
                    }
                  />
                </FormControl>
                <StoreCardPreview config={changes} />
              </VStack>
            </HStack>
          </CheckboxGroup>
        </FormControl>
        <HStack justify="space-between" alignItems="flex-start" w="100%">
          <FormControl>
            <FormLabel fontSize="18px" fontWeight="bold">
              Would you like clients to suggest new {pluralize(storeLabel)} to
              you?
              {changes?.allowSuggestions && (
                <IconButton
                  aria-label="Brush button for allow suggestions"
                  ml={3}
                  icon={<IoBrush />}
                  onClick={() => renderStyleModal("allowSuggestions")}
                />
              )}
            </FormLabel>

            {changes?.allowSuggestions && (
              <>
                <FormHelperText>
                  Dathic provides a form for users to suggest new stores and
                  report responses to you.
                  <br />
                  Want to redirect users to a custom form? Dathic will not
                  report responses from custom forms.
                </FormHelperText>
                <Input
                  {...register("suggestForm")}
                  placeholder="Insert URL to redirect user to a custom form"
                  type="url"
                />
              </>
            )}
          </FormControl>
          <RadioGroup value={changes.allowSuggestions}>
            <HStack mr={15} alignItems="flex-start">
              <Radio {...register("allowSuggestions")} value="true">
                Yes
              </Radio>
              <Radio {...register("allowSuggestions")} value="">
                No
              </Radio>
            </HStack>
          </RadioGroup>
          <Box w="27rem" minW="27rem" h={28} pos="relative" overflow="hidden">
            <VStack
              w="100%"
              bg={selectedPrimaryColor}
              p={3}
              rounded="lg"
              spacing={5}
              pos="absolute"
              bottom={0}
            >
              <Box h={10} w="100%" overflow="hidden" mt={5}>
                <Box w="100%" bg="white" rounded="lg" p={3} h={20}>
                  Whole Foods Market - 8003 Turkey Lake Rd, Orlando, FL 32819
                </Box>
              </Box>
              {changes.allowSuggestions === "true" && (
                <Button color={selectedSecondaryColor} variant="link">
                  {toUpper(`Suggest ${storeLabel}`)}
                </Button>
              )}
            </VStack>
          </Box>
        </HStack>
        <FormControl isInvalid={!changes?.initialCoordinates}>
          <FormLabel fontSize="18px" fontWeight="bold">
            Select initial map location
          </FormLabel>
          <HStack alignItems="flex-start" spacing={10}>
            <VStack flex={1} spacing={5} zIndex={1}>
              <HStack w="100%" zIndex={2}>
                <Box flex={1}>
                  <FormHelperText>Location</FormHelperText>
                  <LocationSearchInput
                    onGeocode={(res: any) => {
                      const latitude = res.geometry.location.lat() ?? "";
                      const longitude = res.geometry.location.lng() ?? "";
                      setValue(
                        "initialCoordinates",
                        latitude && longitude ? { latitude, longitude } : null,
                        { shouldDirty: true }
                      );
                    }}
                    style={undefined}
                    inputProps={{ placeholder: selectedLocation }}
                    onChange={undefined}
                  />
                  {!changes?.initialCoordinates &&
                    !orgProperties?.store_locator?.initialCoordinates && (
                      <FormHelperText color="tomato">
                        Enter valid location to find coordinates
                      </FormHelperText>
                    )}
                </Box>
                <Box flex={1}>
                  <FormHelperText>
                    Prioritize user location when available
                  </FormHelperText>
                  <Checkbox {...register("initialCoordinatesFromIP")} />
                </Box>
              </HStack>
              <Box w="100%" zIndex={1}>
                <FormHelperText>Zoom</FormHelperText>
                <Slider
                  sliderProps={{
                    defaultValue: changes.initialZoom,
                    value: changes.initialZoom,
                    min: 3,
                    max: 16,
                    step: 0.4,
                    onChange: (v) =>
                      setValue("initialZoom", v, { shouldDirty: true }),
                  }}
                  type="single"
                >
                  <SliderMark value={13}>
                    <Badge>Zipcode</Badge>
                  </SliderMark>
                  <SliderMark value={7}>
                    <Badge>State</Badge>
                  </SliderMark>
                  <SliderMark value={3}>
                    <Badge>Country</Badge>
                  </SliderMark>
                </Slider>
              </Box>
              <Box w="100%" zIndex={1}>
                <FormHelperText>
                  If no results in selected area, move the map to closest
                  locations
                </FormHelperText>
                <Checkbox {...register("fallbackLocationToStores")} />
              </Box>
            </VStack>
            <DraggableMapPin
              coordinates={changes.initialCoordinates || undefined}
              zoom={changes.initialZoom}
              draggable={false}
              scrollZoom={false}
              containerProps={{ w: "27rem" }}
              markerPin={
                <Box
                  w={3}
                  h={3}
                  rounded="full"
                  shadow="base"
                  bg={
                    (changes?.mapPins?.type === "color" &&
                      changes?.mapPins?.value) ||
                    "red"
                  }
                />
              }
              theme={changes.mapTheme}
              onChangeTheme={(newTheme) => setValue("mapTheme", newTheme)}
            />
          </HStack>
        </FormControl>
        <FormControl
          isInvalid={
            !!getTrackingId() &&
            !new RegExp(`^${usingUA ? "UA" : "G"}-`).test(getTrackingId())
          }
        >
          <FormLabel fontSize="18px" fontWeight="bold">
            Google Analytics Measurement ID
          </FormLabel>
          <FormHelperText>
            Used to track your {storeLabel} Locator with Google Analytics. For
            help configuring Google Analytics with {storeLabel} Locator,{" "}
            <Button
              variant="link"
              onClick={() => window.open("mailto:info@dathic.com")}
            >
              please email us
            </Button>
            .
          </FormHelperText>

          <FormLabel fontSize={12} mb="0">
            <Switch
              isChecked={usingUA}
              onChange={(e) => {
                setUsingUA(e.target.checked);
                setValue("GAMeasurementId", "", { shouldDirty: true });
                setValue("UATrackingId", "", { shouldDirty: true });
              }}
            />{" "}
            Universal Analytics
          </FormLabel>
          <Input
            placeholder={usingUA ? "UA- ..." : "GA4 Measurement ID: G- ..."}
            value={getTrackingId()}
            onChange={(e) => {
              setValue("UATrackingId", usingUA ? e.target.value : "", {
                shouldDirty: true,
              });
              setValue("GAMeasurementId", !usingUA ? e.target.value : "", {
                shouldDirty: true,
              });
            }}
          />
          {getTrackingId() &&
            !new RegExp(`^${usingUA ? "UA" : "G"}-`).test(getTrackingId()) && (
              <FormControl color="tomato">
                Must begin with {usingUA ? "UA" : "G"}-
              </FormControl>
            )}
        </FormControl>
        <FormControl isRequired>
          <FormLabel fontSize="18px" fontWeight="bold">
            Notification Email
          </FormLabel>
          <FormHelperText>
            Email to send notifications regarding the store locator. Such as
            when a new {storeLabel} suggestion is created.
          </FormHelperText>
          <Input
            {...register("notificationEmail", { required: true })}
            type="email"
          />
        </FormControl>
        <Button
          rightIcon={<CopyIcon />}
          onClick={() => navigator.clipboard.writeText(JSON.stringify(changes))}
        >
          Copy config to clipboard
        </Button>
        <NavButtons
          isDirty={formState.isDirty}
          handleClose={handleClose}
          isLoading={isLoading}
        />
      </VStack>
    </form>
  );
}

export default StoreLocatorConfig;
