import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Divider,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { DataType } from "ka-table";
import { ICellEditorProps, ICellTextProps } from "ka-table/props";
import _ from "lodash";
import pluralize from "pluralize";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import XLSX from "xlsx";
import {
  selectAccessToken,
  selectOrgProperties,
  selectProducts,
} from "../../app/appSlice";
import { useAppDispatch, useAppSelector } from "../../app/store";
import {
  BatchActionRenderProps,
  CustomColumn,
  EntityTable,
} from "../../components/entity-table/EntityTable";
import { ResponsiveModal } from "../../components/responsive-modal";
import MessageModalContext from "../../contexts/MessageModalContext";
import { Product } from "../../domain/Product";
import { ProductStore } from "../../domain/ProductStore";
import { Store } from "../../domain/Store";
import {
  deleteProductStoresBatch,
  getStores,
  postProductStoresBatch,
} from "../../services/api.service";
import { trackClick } from "../../services/tracking.service";
import { camelCaseWords } from "../../utils/stringUtils";
import { refreshIframe } from "../store-locator/storeLocatorSlice";
import { useUpload } from "./UploadHooks";

export type State = {
  stores?: Store[];
  existingItems?: Store[];
  validRelations?: ProductStore[];
  results?: { toRemove: any[]; toAdd: any[] };
};

type Props = {
  state: State;
  setState: React.Dispatch<React.SetStateAction<State | undefined>>;
  noModals?: boolean;
  actionsFromOutside?: React.SetStateAction<any>;
  onFinish?: Function;
};

const ToggleApproval = ({
  selectedRows,
  setDenied,
  rowData,
  type,
}: {
  selectedRows: (Store | Product)[];
  rowData: Store | Product;
  setDenied: React.Dispatch<React.SetStateAction<ProductStore[]>>;
  type: "store" | "product";
}) => {
  const batchUpdateStores = (
    items: (Store | Product)[],
    newStatus: "deny" | ""
  ) => {
    const uniqueItems = new Set(
      items.map((item) => `${item.id},${rowData.id}`)
    );
    setDenied((oldDenied) => {
      let newDenied = [...oldDenied];
      if (newStatus === "deny") {
        newDenied.push(
          ...items.map(
            (p) =>
              new ProductStore({
                [type === "store" ? "store_id" : "product_id"]: p.id,
                [type === "store" ? "product_id" : "store_id"]: rowData.id,
              })
          )
        );
      } else {
        newDenied = newDenied.filter(
          (p) =>
            !uniqueItems.has(
              `${p[type === "store" ? "store_id" : "product_id"]},${
                p[type === "store" ? "product_id" : "store_id"]
              }`
            )
        );
      }
      return newDenied;
    });
  };
  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<CheckIcon size="1rem" />}
        isDisabled={!selectedRows.length}
        aria-label={"Batch add or ignore button"}
      >
        Change status
      </MenuButton>
      <MenuList>
        <MenuItem
          icon={<CheckIcon size="1rem" />}
          onClick={() => batchUpdateStores(selectedRows, "")}
          color="green"
        >
          {selectedRows.every((r: any) => r.action === "removed")
            ? "Remove"
            : selectedRows.every((r: any) => r.action === "added")
            ? "In-store"
            : "Add or remove"}
        </MenuItem>
        <MenuItem
          icon={<CloseIcon size="1rem" />}
          onClick={() => batchUpdateStores(selectedRows, "deny")}
        >
          {selectedRows.every((r: any) => r.action === "removed")
            ? "In-store"
            : selectedRows.every((r: any) => r.action === "added")
            ? "Remove"
            : "Ignore"}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const calculateProducts = (
  s: Store,
  denied: ProductStore[],
  validRelations?: ProductStore[]
) => {
  const storeProducts = (s.searched?.orgStore?.products || []).map(
    (sp: any) => ({ ...sp, source: "store" })
  );
  const fileProducts = (
    validRelations?.filter((v) => v.store_id === s.id) || []
  ).map((sp: any) => ({ ...sp, source: "file" }));
  const added = fileProducts.filter(
    (p: any) =>
      !denied.find(
        (d) => d.product_id === p.product_id && d.store_id === s.id
      ) && !storeProducts.map((sp: any) => sp.product_id).includes(p.product_id)
  );
  const removed = storeProducts.filter(
    (p: any) =>
      !denied.find(
        (d) => d.product_id === p.product_id && d.store_id === s.id
      ) && !fileProducts.map((sp: any) => sp.product_id).includes(p.product_id)
  );
  return { storeProducts, fileProducts, removed, added };
};

const ConfirmationMessage = ({
  validRelations,
  stores,
  products,
  perspective,
  setPerspective,
  hideRemoved = false,
  denied,
}: {
  validRelations: ProductStore[];
  denied: ProductStore[];
  hideRemoved?: boolean;
  stores: Store[];
  products: Product[];
  perspective: "store" | "product";
  setPerspective: (perspective: "store" | "product") => void;
}) => {
  const _stores =
    stores?.map((s) => ({
      ...s,
      products: calculateProducts(s, denied, validRelations),
    })) || [];
  return (
    <VStack divider={<Divider />} textAlign={"start"} spacing={5}>
      <VStack>
        <Text fontSize="lg" fontWeight="bold" color="green">
          {pluralize(
            "product",
            Array.from(
              new Set(
                _stores
                  .flatMap((s) => s.products.added)
                  .map((ps: ProductStore) => ps.product_id)
              )
            ).length,
            true
          )}{" "}
          will be made available in{" "}
          {pluralize(
            "location",
            _stores.filter((s) => !!s.products.added.length).length,
            true
          )}
        </Text>
      </VStack>
      {!hideRemoved &&
        !!_stores.filter((s) => !!s.products.removed.length).length && (
          <>
            {Array.from(
              new Set(
                _stores
                  .flatMap((s) => s.products.removed)
                  .map((ps: ProductStore) => ps.product_id)
              )
            ).length && (
              <VStack>
                <Text fontSize="lg" fontWeight="bold" color="orange">
                  {pluralize(
                    "product",
                    Array.from(
                      new Set(
                        _stores
                          .flatMap((s) => s.products.removed)
                          .map((ps: ProductStore) => ps.product_id)
                      )
                    ).length,
                    true
                  )}{" "}
                  will be removed from{" "}
                  {pluralize(
                    "location",
                    _stores.filter((s) => !!s.products.removed.length).length,
                    true
                  )}
                </Text>
              </VStack>
            )}
          </>
        )}
    </VStack>
  );
};

const ProductImageField = ({ rowData, column }: ICellTextProps) => {
  return (
    <VStack>
      {rowData[column.key] ? (
        <Image src={rowData[column.key]} w={50} h={50} objectFit="contain" />
      ) : (
        <Box
          textAlign="center"
          p={3}
          rounded="lg"
          bg="gray.100"
          border="1px solid"
          lineHeight="1em"
          color="tomato"
          borderColor="tomato"
        >
          No Image
        </Box>
      )}
    </VStack>
  );
};

const storeColumns: (
  setDenied: React.Dispatch<React.SetStateAction<ProductStore[]>>,
  denied: ProductStore[]
) => CustomColumn<Store>[] = (setDenied, denied) => [
  {
    title: "Name",
    key: "name",
    Render: (p) => {
      console.log(p.rowData);
      return (
        <VStack>
          <Text>{p.value}</Text>
          {p.rowData.newStore === "true" ? (
            <Badge colorScheme="green">new</Badge>
          ) : (
            <></>
          )}
        </VStack>
      );
    },
  },
  {
    title: "Chain",
    key: "chain",
  },
  {
    title: "Address",
    key: "address",
    style: { width: 200 },
  },
  {
    title: "State",
    key: "state",
  },
  {
    title: "City",
    key: "city",
  },
  {
    title: "Zipcode",
    key: "zipcode",
  },
  {
    title: "Products to add",
    key: "productsToadd",
    Render(props) {
      return <ProductSelect {...props} setDenied={setDenied} denied={denied} />;
    },
  },
  {
    title: "Current products",
    key: "currentProducts",
    Render(props) {
      return (
        <ProductSelect
          {...props}
          setDenied={setDenied}
          denied={denied}
          isCurrent
        />
      );
    },
  },
];
const productColumns: (
  setDenied: React.Dispatch<React.SetStateAction<ProductStore[]>>,
  denied: ProductStore[],
  isLoadingStores: boolean,
  validRelations?: ProductStore[]
) => CustomColumn<Product>[] = (
  setDenied,
  denied,
  isLoadingStores,
  validRelations
) => [
  {
    title: "Name",
    key: "name",
  },
  {
    title: "Image",
    key: "url",
    Render: ProductImageField,
    style: { width: 100 },
  },
  {
    title: "Stores to add to",
    key: "fileStores",
    Render(props) {
      return (
        <StoreSelect
          {...props}
          setDenied={setDenied}
          denied={denied}
          validRelations={validRelations}
        />
      );
    },
  },
  {
    title: "Current stores",
    key: "currentStores",
    Render(props) {
      return isLoadingStores ? (
        <Spinner />
      ) : (
        <StoreSelect
          {...props}
          setDenied={setDenied}
          denied={denied}
          validRelations={validRelations}
          isCurrent
        />
      );
    },
  },
];

const StatusSelect = (
  props: ICellEditorProps & {
    isEditing?: boolean;
    data?: any[];
    partnerObject: Store | Product;
    type: "store" | "product";
    setDenied: React.Dispatch<React.SetStateAction<ProductStore[]>>;
  }
) => {
  const { rowData, type, setDenied, partnerObject } = props;
  return (
    <Select
      defaultValue={rowData.status}
      onChange={(event) => {
        const value = event.currentTarget.value;
        setDenied?.((oldDenied) => {
          let newDenied = [...oldDenied];
          const shouldAddRelation = value === "";
          if (shouldAddRelation) {
            newDenied = newDenied.filter(
              (p) =>
                p[type === "store" ? "store_id" : "product_id"] !==
                  rowData.id ||
                p[type === "store" ? "product_id" : "store_id"] !==
                  partnerObject.id
            );
          } else {
            newDenied.push(
              new ProductStore({
                [type === "store" ? "store_id" : "product_id"]: rowData.id,
                [type === "store" ? "product_id" : "store_id"]:
                  partnerObject.id,
              })
            );
          }
          return newDenied;
        });
      }}
    >
      {["", "deny"].map((option) => (
        <option key={option} value={option}>
          {option === ""
            ? rowData.action === "removed"
              ? "Remove"
              : "In-store"
            : rowData.action === "removed"
            ? "In-store"
            : "Remove"}
        </option>
      ))}
    </Select>
  );
};

const StoreSelect = (
  props: ICellEditorProps & {
    isEditing?: boolean | undefined;
    data?: Product[] | undefined;
    setDenied: React.Dispatch<React.SetStateAction<ProductStore[]>>;
    denied: ProductStore[];
    validRelations?: ProductStore[];
    isCurrent?: boolean;
  }
) => {
  const messageModalContext = useContext(MessageModalContext);
  const {
    value = [],
    rowData,
    setDenied,
    denied,
    validRelations,
    isCurrent,
  } = props;
  return (
    <Button
      isDisabled={!value.length}
      onClick={() =>
        messageModalContext.showModal({
          title: (
            <HStack alignItems={"flex-end"}>
              {!isCurrent ? (
                <Text>Stores to add </Text>
              ) : (
                <Text>Current stores with </Text>
              )}
              <Badge fontSize="lg">{rowData?.name}</Badge>
            </HStack>
          ),
          modalProps: { size: "6xl" },
          initialDialogState: { denied },
          hideCloseButton: true,
          actions: [{ label: "Confirm" }],
          message(dialogState, setDialogState) {
            const batchActions = [
              {
                Render: ({
                  selectedRows,
                  data,
                }: BatchActionRenderProps<Partial<Store>>) => (
                  <ToggleApproval
                    selectedRows={selectedRows}
                    setDenied={(f) => {
                      setDialogState((old: any) => ({
                        ...old,
                        denied:
                          typeof f !== "function" ? f : f(dialogState.denied),
                      }));
                      setDenied(f);
                    }}
                    type="store"
                    rowData={rowData}
                  />
                ),
              },
            ];

            const columns: CustomColumn<any>[] = [
              {
                title: "ID",
                key: "id",
                visible: false,
              },
              {
                title: "Status",
                dataType: DataType.String,
                key: "status",
                info: `Stores that you add will be added to the product`,
                filterOptions: ["", "deny"].map((status) => ({
                  value: status,
                  label: status === "" ? "Added" : "Ignored",
                })),
                Render: (p: any) => (
                  <StatusSelect
                    {...p}
                    setDenied={(f) => {
                      setDialogState((old: any) => ({
                        ...old,
                        denied:
                          typeof f !== "function" ? f : f(dialogState.denied),
                      }));
                      setDenied(f);
                    }}
                    partnerObject={rowData}
                    type="store"
                  />
                ),
                format: (value: string) => camelCaseWords(value),
                default: "None",
              },
              {
                title: "Name",
                key: "name",
              },
              {
                title: "Chain",
                key: "chain",
              },
              {
                title: "Address",
                key: "address",
                style: { width: 200 },
              },
              {
                title: "State",
                key: "state",
              },
              {
                title: "City",
                key: "city",
              },
              {
                title: "Zipcode",
                key: "zipcode",
              },
            ];
            return (
              <VStack>
                <EntityTable
                  allowBatchActions
                  additionalBatchActions={batchActions}
                  initialTableProps={{ columns }}
                  dataFromOutside={value.map((s: Store) => {
                    const products = calculateProducts(s, [], validRelations);
                    const { removed, added } = products;
                    const isAdded = added.find(
                      (sp: any) => sp.product.name === rowData?.name
                    )?.product_id;
                    return {
                      ...s,
                      status: dialogState.denied.find(
                        (d: any) =>
                          d.store_id === s.id && d.product_id === rowData.id
                      )
                        ? "deny"
                        : "",
                      products,
                      action: isAdded ? "added" : removed ? "removed" : "",
                    };
                  })}
                />
              </VStack>
            );
          },
        })
      }
    >
      {value.length} stores
    </Button>
  );
};

const ProductSelect = (
  props: ICellEditorProps & {
    isEditing?: boolean | undefined;
    data?: Store[] | undefined;
    setDenied: React.Dispatch<React.SetStateAction<ProductStore[]>>;
    denied: ProductStore[];
    isCurrent?: boolean;
  }
) => {
  const messageModalContext = useContext(MessageModalContext);
  const { value = [], rowData, setDenied, denied, isCurrent } = props;
  const fileProducts = rowData.productsToadd || [];
  const storeProducts = rowData.currentProducts || [];
  const added = fileProducts.filter(
    (p: any) =>
      !storeProducts.map((sp: any) => sp.product_id).includes(p.product_id)
  );
  const removed = storeProducts.filter(
    (p: any) =>
      !fileProducts.map((sp: any) => sp.product_id).includes(p.product_id)
  );
  return (
    <Button
      isDisabled={!value.length}
      onClick={() =>
        messageModalContext.showModal({
          title: (
            <HStack alignItems={"flex-end"}>
              {!isCurrent ? (
                <Text>Products in store </Text>
              ) : (
                <Text>Current products in store </Text>
              )}
              <Badge fontSize="lg">
                {[rowData?.chain || rowData?.name, rowData?.address]
                  .filter((c) => !!c)
                  .join(" - ")}
              </Badge>
            </HStack>
          ),
          modalProps: { size: "6xl" },
          initialDialogState: { denied },
          hideCloseButton: true,
          actions: [{ label: "Confirm" }],
          message(dialogState, setDialogState) {
            const batchActions = [
              {
                Render: ({
                  selectedRows,
                  data,
                }: BatchActionRenderProps<Partial<Product>>) => (
                  <ToggleApproval
                    selectedRows={selectedRows}
                    rowData={rowData}
                    setDenied={(f) => {
                      setDialogState((old: any) => ({
                        ...old,
                        denied:
                          typeof f !== "function" ? f : f(dialogState.denied),
                      }));
                      setDenied(f);
                    }}
                    type={"product"}
                  />
                ),
              },
            ];

            const columns: CustomColumn<any>[] = [
              {
                title: "ID",
                key: "id",
                visible: false,
              },
              {
                title: "Status",
                dataType: DataType.String,
                key: "status",
                info: `Products that you add will be added to the store`,
                filterOptions: ["", "deny"].map((status) => ({
                  value: status,
                  label: status === "" ? "Added" : "Ignored",
                })),
                Render: (p: any) =>
                  p.rowData.action ? (
                    <StatusSelect
                      {...p}
                      setDenied={(f) => {
                        setDialogState((old: any) => ({
                          ...old,
                          denied:
                            typeof f !== "function" ? f : f(dialogState.denied),
                        }));
                        setDenied(f);
                      }}
                      partnerObject={rowData}
                      type="products"
                    />
                  ) : (
                    <Text>No change</Text>
                  ),
                format: (value: string) => camelCaseWords(value),
                default: "None",
              },
              {
                title: "Name",
                key: "name",
              },
            ];
            return (
              <VStack>
                <EntityTable
                  initialTableProps={{ columns }}
                  allowBatchActions
                  additionalBatchActions={batchActions}
                  dataFromOutside={Array.from(
                    new Set(value.map((p: any) => p.product.name))
                  ).map<Product>((name) => {
                    const productStore = value.find(
                      (sp: any) => sp.product.name === name
                    );
                    const isAdded = added.find(
                      (sp: any) => sp.product.name === name
                    )?.product_id;
                    const isRemoved = removed.find(
                      (sp: any) => sp.product.name === name
                    )?.product_id;
                    return {
                      ...productStore?.product,
                      status: dialogState.denied.find(
                        (d: any) =>
                          d.product_id === productStore?.product_id &&
                          d.store_id === rowData.id
                      )
                        ? "deny"
                        : "",
                      name,
                      id: isAdded || isRemoved,
                      action: isAdded ? "added" : isRemoved ? "removed" : "",
                    } as unknown as Product;
                  })}
                />
              </VStack>
            );
          },
        })
      }
    >
      {pluralize(
        "Product",
        Array.from(
          new Set(
            value.map((p: any) => {
              return p.product?.name;
            })
          )
        ).length,
        true
      )}
    </Button>
  );
};

export default function UploadProductStoreProxy({
  state,
  setState,
  noModals,
  actionsFromOutside,
  onFinish,
}: Props) {
  const messageModalContext = useContext(MessageModalContext);
  const dispatch = useAppDispatch();
  const products = useAppSelector(selectProducts);
  const accessToken = useAppSelector(selectAccessToken);
  const orgProperties = useAppSelector(selectOrgProperties);
  const [denied, setDenied] = useState<ProductStore[]>([]);
  const [currentStores, setCurrentStores] = useState<Store[]>();
  const [isLoadingStores, setIsLoadingStores] = useState(false);
  const [uploadingItems, setUploadingItems] = useState(false);
  const storeLabel = useMemo(
    () => orgProperties?.properties?.storeNameReplacement || "Store",
    [orgProperties]
  );
  const postBatch = useCallback(async (items: any[], accessToken: string) => {
    const { product_store_id } = await postProductStoresBatch(
      accessToken,
      items.map((i) => new ProductStore(i).buildForPost())
    );
    return product_store_id;
  }, []);
  const deleteBatch = useCallback(async (items: any[], accessToken: string) => {
    const { product_store_id } = await deleteProductStoresBatch(
      accessToken,
      items.map((i) => new ProductStore(i).id)
    );
    return product_store_id;
  }, []);
  const { uploadItems, uploadFileToS3, deleteItems } = useUpload({
    postBatch,
    deleteBatch,
  });
  const { stores, validRelations, existingItems } = state;

  const generateDownload = useCallback(
    (
      filename: string,
      folderName: string,
      uploadToS3?: boolean,
      toAdd?: any[],
      toRemove?: any[]
    ) => {
      if (!toAdd?.length && !toRemove?.length) return;
      const added = toAdd?.map((s) =>
        _.pick(s, ["id", "store_id", "product_id"])
      );
      const removed = toRemove?.map((s) =>
        _.pick(s, ["id", "store_id", "product_id"])
      );
      var wb = XLSX.utils.book_new();

      if (added) {
        const addedSheet = XLSX.utils.json_to_sheet(added);
        XLSX.utils.book_append_sheet(wb, addedSheet, "added");
      }
      if (removed) {
        const removedSheet = XLSX.utils.json_to_sheet(removed);
        XLSX.utils.book_append_sheet(wb, removedSheet, "removed");
      }

      if (uploadToS3) {
        const wbBlob = new Blob(
          [XLSX.write(wb, { type: "array", bookType: "xlsx" })],
          {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          }
        );
        const file = new File([wbBlob], "product_store.xlsx", {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        uploadFileToS3({
          file,
          folderName,
          getFileName: filename,
        }).then((res) => {
          console.log(res);
        });
      }
    },
    [orgProperties, uploadFileToS3]
  );

  useEffect(() => {
    if (!currentStores && !isLoadingStores) {
      setIsLoadingStores(true);
      getStores(
        [],
        [],
        [],
        [],
        [],
        undefined,
        true,
        accessToken,
        orgProperties?.properties?.country,
        [],
        [],
        [],
        [],
        undefined,
        [],
        [
          "id",
          "store_id",
          "parent_id",
          "name",
          "address",
          "chain",
          "state",
          "county",
          "city",
          "zipcode",
          "latitude",
          "longitude",
          "status",
          "created_at",
          "category",
          "products",
          "email",
          "type",
          "phone",
          "description",
          "image_url",
          "featured_content",
          "secondary_images",
          "start_date",
          "end_date",
          "recurrence_rule",
          "origin",
        ]
      )
        .then((cs?: Store[]) => {
          setCurrentStores(cs);
          const _stores = [
            ...((cs || []).map((s) => ({
              ...s,
              searched: {
                orgStore: {
                  products: s.products?.filter((ps) =>
                    validRelations
                      ?.map((r) => r.product_id)
                      .includes(ps.product_id)
                  ),
                },
              },
            })) as Store[]),
          ];
          let toRemove = _stores
            .flatMap((s) =>
              (s.products || []).filter(
                (p) =>
                  !validRelations?.find(
                    (r) =>
                      r.product_id === p.product_id && r.store_id === p.store_id
                  )
              )
            )
            .map((i) => new ProductStore(i));
          setDenied((old) => [...old, ...toRemove]);
        })
        .catch(() => setCurrentStores([]))
        .finally(() => setIsLoadingStores(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, currentStores, isLoadingStores, orgProperties]);

  const storesForTable = useMemo(
    () => {
      return (
        [...(stores || []), ...(existingItems || [])]
          ?.map((s) => {
            const { storeProducts, fileProducts } = calculateProducts(
              s,
              denied,
              validRelations
            );
            const productIds = [...storeProducts, ...fileProducts].map(
              (p) => p.product_id
            );
            return {
              ...s,
              newStore: !existingItems?.find((cs) => cs.id === s.id)
                ? "true"
                : "false",
              status:
                productIds.length &&
                productIds.every((id) =>
                  denied
                    .filter((p) => p.store_id === s.id)
                    .map((p) => p.product_id)
                    .includes(id)
                )
                  ? "deny"
                  : "",
              productsToadd: fileProducts.map((sp: any) => ({
                ...sp,
                status: !!denied.filter(
                  (p) => p.store_id === s.id && p.product_id === sp.product_id
                ).length
                  ? "deny"
                  : "",
              })),
              currentProducts: storeProducts.map((sp: any) => ({
                ...sp,
                status: !!denied.filter(
                  (p) => p.store_id === s.id && p.product_id === sp.product_id
                ).length
                  ? "deny"
                  : "",
              })),
            };
          })
          .filter((s) => s.productsToadd.length || s.currentProducts.length)
          .map((s) =>
            _.pick(s, [
              "id",
              "newStore",
              ...storeColumns(setDenied, denied).map((c) => c.key),
            ])
          ) || []
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stores, currentStores, existingItems, denied]
  );

  const productsForTable = useMemo(
    () => {
      const allProducts = Array.from(
        new Set(validRelations?.map((p: any) => p.product.name) || [])
      ).map<Product | undefined>((name) => {
        const productStores = validRelations?.filter(
          (sp: any) => sp.product.name === name
        );
        const fileStores = [...(stores || []), ...(existingItems || [])]
          .filter((s) => productStores?.find((ps) => ps.store_id === s.id))
          .map((s) => ({
            ...s,
            status: denied.filter(
              (p) =>
                productStores?.find((ps) => ps.product_id === p.product_id) &&
                p.store_id === s.id
            ).length
              ? "deny"
              : "",
          }));
        const _currentStores = (currentStores || [])
          .filter((s) =>
            s.products?.find(
              (p) =>
                !productStores?.find((ps) => ps.product_id === p.product_id) &&
                p.product?.name === name
            )
          )
          .map((s) => ({
            ...s,
            status: denied.filter(
              (p) =>
                productStores?.find((ps) => ps.product_id === p.product_id) &&
                p.store_id === s.id
            ).length
              ? "deny"
              : "",
          }));
        return {
          ...(productStores?.[0]?.product || {}),
          status:
            fileStores.length + _currentStores.length &&
            [...fileStores, ..._currentStores]
              .map((s) => s.id)
              .every((id) =>
                denied
                  .filter((p) =>
                    productStores?.find((ps) => ps.product_id === p.product_id)
                  )
                  .map((p) => p.store_id)
                  .includes(id)
              )
              ? "deny"
              : "",
          fileStores,
          currentStores: _currentStores,
        } as unknown as Product;
      });
      return allProducts;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stores, currentStores, existingItems, denied]
  );
  const [perspective, setPerspective] = useState<"store" | "product">(
    storesForTable.length < productsForTable.length ? "store" : "product"
  );

  const doProductAssociations = useCallback(async () => {
    setUploadingItems(true);
    const _stores =
      [
        ...(state.stores || []),
        ...(state.existingItems || []),
        ...((
          currentStores?.filter(
            (s) =>
              !state.validRelations?.find((r) => r.store_id === s.id) &&
              state.validRelations?.find((r) =>
                s.products?.map((ps) => ps.product_id).includes(r.product_id)
              )
          ) || []
        ).map((s) => ({
          ...s,
          searched: {
            orgStore: {
              products: s.products?.filter((ps) =>
                state.validRelations
                  ?.map((r) => r.product_id)
                  .includes(ps.product_id)
              ),
            },
          },
        })) as Store[]),
      ]?.map((s) => ({
        ...s,
        products: calculateProducts(s, denied, state.validRelations),
      })) || [];
    let toRemove: any[] = [];
    let toAdd: any[] = [];
    let addResult: {
      goodItems: {
        item: unknown;
        original: any;
      }[];
      badItems: any[];
      existingItems: any[];
    };
    toRemove = _stores
      .flatMap((s) => s.products.removed)
      .filter(
        (v) =>
          !denied.filter(
            (d) => d.product_id === v.product_id && d.store_id === v.store_id
          ).length
      );
    try {
      await deleteItems(toRemove);
      toast.success(
        `Removed ${
          new Set(toRemove.map((r) => r.product_id)).size
        } products from ${pluralize(
          storeLabel,
          new Set(toRemove.map((r) => r.product_id)).size,
          true
        )}`
      );
    } catch (error) {
      toast.warn(`Problem removing products`);
    }
    toAdd = _stores
      .flatMap((s) => s.products.added)
      .filter(
        (v) =>
          !denied.filter(
            (d) => d.product_id === v.product_id && d.store_id === v.store_id
          ).length
      );
    try {
      addResult = await uploadItems(toAdd);
      toast.success(
        `Added ${
          new Set(
            addResult?.goodItems?.map(
              ({ item }) => (item as ProductStore).product_id
            )
          ).size || 0
        } products to ${pluralize(
          storeLabel,
          new Set(
            addResult?.goodItems?.map(
              ({ item }) => (item as ProductStore).store_id
            )
          ).size || 0,
          true
        )}`
      );
      setState(undefined);
    } catch (error) {
      addResult = { goodItems: [], badItems: [], existingItems: [] };
      toast.warn(`Problem adding products`);
    }
    const bucket = process.env.REACT_APP_S3_BUCKET;
    const folderName = "product-store-files";
    const filename = `${
      orgProperties?.organization_id
    }_product_store_${new Date().getTime()}.xlsx`;
    const fileKey = `${bucket}:${folderName}/${filename}`;
    generateDownload(
      filename,
      folderName || "",
      true,
      (addResult?.goodItems || []).map((g) => g.item),
      toRemove
    );
    dispatch(refreshIframe());
    setUploadingItems(false);
    setState((old) => ({ ...old, results: { toRemove, toAdd } }));
    onFinish?.({ toRemove, toAdd, fileKey });
  }, [
    currentStores,
    deleteItems,
    denied,
    dispatch,
    state.existingItems,
    generateDownload,
    setState,
    state.stores,
    uploadItems,
    state.validRelations,
    onFinish,
    orgProperties,
    storeLabel,
  ]);

  const onSubmit = useCallback(() => {
    trackClick("upload-product-store-submit", "", () =>
      messageModalContext.showModal({
        title: `Confirm products in stores`,
        hideCloseButton: true,
        message: (
          <ConfirmationMessage
            denied={denied}
            validRelations={state.validRelations || []}
            stores={
              [...(state.stores || []), ...(state.existingItems || [])] || []
            }
            products={products}
            perspective={perspective}
            setPerspective={setPerspective}
          />
        ),
        actions: [
          { label: "Go back", isLeastDestructive: true },
          {
            label: "Confirm",
            callback: () => {
              trackClick(
                "upload-product-store-confirm",
                "",
                doProductAssociations
              );
            },
            props: { colorScheme: "blue", mt: 5 },
          },
        ],
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    doProductAssociations,
    state.existingItems,
    perspective,
    products,
    state.stores,
    state.validRelations,
    denied,
  ]);

  useEffect(() => {
    if (actionsFromOutside) {
      actionsFromOutside({
        onSubmit,
      });
    }
  }, [actionsFromOutside, onSubmit]);

  const initialTableProps = useMemo(() => {
    return {
      columns:
        perspective === "store"
          ? storeColumns(setDenied, denied)
          : productColumns(
              setDenied,
              denied,
              isLoadingStores,
              state.validRelations
            ),
    };
  }, [denied, isLoadingStores, perspective, state.validRelations]);

  const getBody = useCallback(
    () => (
      <VStack spacing={5} h="100%" w="100%" flex={1} alignItems="flex-start">
        <HStack spacing={5} w="100%">
          <Tooltip
            label={`The products that will be made available in your list of locations.`}
          >
            <Tag
              variant="subtle"
              cursor={"pointer"}
              onClick={() => setPerspective("product")}
              colorScheme={perspective === "product" ? "blue" : undefined}
              size="lg"
              border={perspective === "product" ? "2px" : "none"}
            >
              <TagLabel>
                Link{" "}
                {pluralize(
                  "product",
                  Array.from(
                    new Set(
                      (state.validRelations || []).map((r) => r.product_id)
                    )
                  ).length,
                  true
                )}{" "}
                to {pluralize(storeLabel)}
              </TagLabel>
            </Tag>
          </Tooltip>
          <Tooltip
            label={`The stores that will have the selected products available.`}
          >
            <Tag
              variant="subtle"
              cursor={"pointer"}
              onClick={() => setPerspective("store")}
              colorScheme={perspective === "store" ? "blue" : undefined}
              size="lg"
              border={perspective === "store" ? "2px" : "none"}
            >
              <TagLabel>
                Link products to{" "}
                {pluralize(storeLabel, storesForTable.length, true)}{" "}
              </TagLabel>
            </Tag>
          </Tooltip>
        </HStack>
        <Box flex={1} w="100%">
          <EntityTable
            initialTableProps={initialTableProps}
            // @ts-ignore
            dataFromOutside={
              perspective === "store" ? storesForTable : productsForTable
            }
          />
        </Box>
      </VStack>
    ),
    [
      initialTableProps,
      perspective,
      productsForTable,
      storesForTable,
      state,
      storeLabel,
    ]
  );

  return !noModals ? (
    <ResponsiveModal isOpen isCentered scrollBehavior="inside" size="full">
      <ModalOverlay />
      <ModalContent h="100%">
        <ModalCloseButton
          onClick={() => {
            setState(undefined);
          }}
        />

        <ModalHeader textAlign="center">Product associations</ModalHeader>

        <ModalBody>{getBody()}</ModalBody>
        <ModalFooter as={HStack} justifyContent={"flex-end"}>
          <Button
            colorScheme="blue"
            isLoading={uploadingItems}
            onClick={onSubmit}
          >
            Upload product associations
          </Button>
        </ModalFooter>
      </ModalContent>
    </ResponsiveModal>
  ) : (
    getBody()
  );
}
