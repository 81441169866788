import { Button, IconButton } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  PlusSquareIcon,
} from "@chakra-ui/icons";
import { Flex, HStack, Text } from "@chakra-ui/layout";
import {
  Box,
  Checkbox,
  Colors,
  FlexProps,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Spacer,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { Select } from "@chakra-ui/select";
import { ITableProps, kaReducer, PagingPosition, Table } from "ka-table";
import {
  clearAllFilters,
  deleteRow,
  deselectAllFilteredRows,
  deselectAllRows,
  deselectRow,
  hideLoading,
  hideNewRow,
  loadData,
  saveNewRow,
  selectAllFilteredRows,
  selectRow,
  setSingleAction,
  showLoading,
  showNewRow,
  updateCellValue,
  updateData,
  updateEditorValue,
  updateFilterRowOperator,
  updateFilterRowValue,
  updatePageIndex,
  updateRow,
} from "ka-table/actionCreators";
import {
  ActionType,
  DataType,
  EditingMode,
  FilteringMode,
  SortingMode,
} from "ka-table/enums";
import { Column } from "ka-table/models";
import {
  ICellEditorProps,
  ICellTextProps,
  IFilterRowEditorProps,
  IHeadCellProps,
  ILoadingProps,
} from "ka-table/props";
import "ka-table/style.scss";
import { DispatchFunc, ValidationFunc } from "ka-table/types";
import { filterAndSearchData } from "ka-table/Utils/FilterUtils";
import {
  areAllFilteredRowsSelected,
  getData,
  getSelectedData,
} from "ka-table/Utils/PropsUtils";
import { debounce, merge } from "lodash";
import { DateTime } from "luxon";
import pluralize from "pluralize";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  MdCheckBox,
  MdOutlineCheckBoxOutlineBlank,
  RiArrowUpDownFill,
} from "react-icons/all";
import { IoTrashOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import AutoSizer from "react-virtualized-auto-sizer";
import XLSX from "xlsx";
import { PopoverTrigger } from "../../features/products/ProductsTable";
import dathicChakraTheme from "../../theme/dathic_chakra_theme";
import InfoPopover from "../InfoPopover";
import "./dathic-table-theme.scss";

const filterCountdownTime = 2000;

const AddButton = ({
  dispatch,
  disabled,
}: IHeadCellProps & { disabled: boolean }) => {
  return (
    <IconButton
      icon={<PlusSquareIcon />}
      isDisabled={disabled}
      onClick={() => {
        dispatch(showNewRow());
      }}
      aria-label={""}
    />
  );
};

const DeleteRow = ({
  dispatch,
  rowKeyValue,
  disabled,
}: ICellTextProps & { disabled: boolean }) => {
  return (
    <IconButton
      icon={<DeleteIcon />}
      isDisabled={disabled}
      onClick={() => dispatch(deleteRow(rowKeyValue))}
      aria-label={""}
    />
  );
};

const SaveButton = ({ dispatch }: ICellEditorProps) => {
  return (
    <HStack>
      <IconButton
        icon={<CheckIcon />}
        onClick={() =>
          dispatch(
            saveNewRow(undefined, {
              validate: true,
            })
          )
        }
        aria-label={""}
      />
      <IconButton
        icon={<CloseIcon />}
        onClick={() => dispatch(hideNewRow())}
        aria-label={""}
      />
    </HStack>
  );
};

export type CustomColumn<T> = Column & {
  filterOptions?: {
    value?: string | number | readonly string[];
    label?: string;
  }[];
  isRequired?: boolean;
  format?: (value: any) => any;
  Render?: (
    props: ICellEditorProps & { isEditing?: boolean; data?: T[] }
  ) => React.ReactElement;
  FilterRender?: (
    props: React.PropsWithChildren<IFilterRowEditorProps>
  ) => React.ReactElement;
  info?: React.ReactElement | string;
  default?: string;
  hideFilter?: boolean;
};

const CustomLookupSelect = (
  props: ICellEditorProps & IFilterRowEditorProps & { shouldUpdate: boolean }
) => {
  const { column, dispatch, shouldUpdate, rowKeyValue } = props;
  const _column: CustomColumn<any> = column;
  const convertToValue = (value: string) => {
    switch (column.dataType) {
      case DataType.Number:
        return +value;
      case DataType.Boolean:
        return Boolean(value);
      case DataType.Date:
        return DateTime.fromJSDate(new Date(value)).toJSDate();

      default:
        return `${value}`;
    }
  };
  return (
    <div>
      <Select
        defaultValue={column.filterRowValue}
        bg="white"
        value={props.value}
        onChange={(event) => {
          const newValue = event.target.value;
          const action = shouldUpdate
            ? updateCellValue(rowKeyValue, column.key, convertToValue(newValue))
            : updateFilterRowValue(column.key, convertToValue(newValue));
          dispatch(action);
        }}
      >
        <option value="" />
        {_column.filterOptions?.map((option) => (
          <option key={option.value as React.Key} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </div>
  );
};

const FilterOperators: React.FC<{ column: Column; dispatch: DispatchFunc }> = ({
  column,
  dispatch,
}) => {
  return (
    <div>
      <Select
        className="form-control"
        bg="white"
        defaultValue={column.filterRowOperator}
        onChange={(event) => {
          dispatch(updateFilterRowOperator(column.key, event.target.value));
        }}
      >
        <option value={"="}>=</option>
        <option value={"<"}>{"<"}</option>
        <option value={">"}>{">"}</option>
        <option value={"<="}>{"<="}</option>
        <option value={">="}>{">="}</option>
        <option value={"range"}>{"range"}</option>
      </Select>
    </div>
  );
};

const FilterEditorWithOperators = (props: IFilterRowEditorProps) => {
  const { column, dispatch } = props;
  const [upperBound, setUpperBound] = useState(0);
  const [lowerBound, setLowerBound] = useState(0);

  const onRangeChange = (bound: string, eVal: string) => {
    const filterRowValue = eVal !== "" ? Number(eVal) : null;
    (bound === "upper" ? setUpperBound : setLowerBound)(filterRowValue || 0);

    const rangeIsValid =
      (bound === "upper" ? lowerBound : upperBound) && filterRowValue;
    const range = rangeIsValid
      ? `${bound === "lower" ? filterRowValue : lowerBound}|${
          bound === "upper" ? filterRowValue : upperBound
        }`
      : null;
    dispatch(updateFilterRowValue(column.key, range));
  };
  return (
    <div>
      <FilterOperators column={column} dispatch={dispatch} />
      {column.filterRowOperator !== "range" && (
        <Input
          defaultValue={column.filterRowValue}
          bg="white"
          style={{ width: 60 }}
          onChange={(event) => {
            const newValue = event.target.value;
            const filterRowValue = newValue !== "" ? Number(newValue) : null;
            dispatch(updateFilterRowValue(column.key, filterRowValue));
          }}
          type="number"
        />
      )}
      {column.filterRowOperator === "range" && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Input
            style={{ width: 60 }}
            bg="white"
            onChange={(event) => onRangeChange("lower", event.target.value)}
            type="number"
          />
          -
          <Input
            style={{ width: 60 }}
            bg="white"
            onChange={(event) => onRangeChange("upper", event.target.value)}
            type="number"
          />
        </div>
      )}
    </div>
  );
};
const FilterEditor = (props: IFilterRowEditorProps) => {
  const { column, dispatch } = props;
  return (
    <Input
      defaultValue={column.filterRowValue}
      bg="white"
      onChange={(event) => {
        const newValue = event.target.value;
        const filterRowValue = newValue !== "" ? newValue : null;
        dispatch(updateFilterRowValue(column.key, filterRowValue));
      }}
    />
  );
};

const validation: ValidationFunc = (validationParams) => {
  const { column, value } = validationParams;
  const _column: CustomColumn<any> = column;
  return !_column.isRequired || value ? "" : "value must be specified";
};

const tablePropsInit: <T>(
  hasLeftButton: boolean,
  hasPostItem: boolean,
  allowBatchActions: boolean,
  putItem?: Function,
  initialTableProps?: InitialTableProps<T>,
  extendedFilter?: (data: T[]) => T[]
) => ITableProps = (
  hasLeftButton,
  hasPostItem,
  allowBatchActions,
  putItem,
  initialTableProps,
  extendedFilter
) => {
  const allColumns = (initialTableProps?.columns || []).map((column) => ({
    style: { width: 150 },
    ...column,
  }));
  if (hasLeftButton || allowBatchActions) {
    allColumns.unshift({
      key: "LeftButton",
      style: { width: 74 },
      isEditable: false,
    });
  }
  if (hasPostItem) {
    allColumns.push({
      key: "addColumn",
      style: { width: 74 },
      isEditable: false,
    });
  }
  return {
    columns: allColumns,
    format: ({ column, value }) => {
      const _column: CustomColumn<any> = column;
      if (_column.format) {
        return _column.format(value);
      }
      if (column.dataType === DataType.Date) {
        return (
          value &&
          DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_SHORT)
        );
      }
    },
    data: [],
    editingMode: EditingMode[putItem ? "Cell" : "None"],
    rowKeyField: "id",
    sortingMode: SortingMode.SingleTripleState,
    filteringMode: FilteringMode.None,
    singleAction: loadData(),
    paging: {
      enabled: false,
      pageIndex: 0,
      pageSize: 50,
      pageSizes: [10, 50, 100],
      position: PagingPosition.Bottom,
    },
    filter: (props) => {
      const { column }: { column: CustomColumn<any> } = props;
      if (
        column.filterRowOperator === "range" &&
        typeof column.filterRowValue === "string"
      ) {
        return (value, filterRowValue) => {
          const [lower, upper] = filterRowValue.split("|");
          return (
            !isNaN(lower) &&
            !isNaN(upper) &&
            !isNaN(value) &&
            value >= +lower &&
            value <= +upper
          );
        };
      }
      if (column.filterOptions?.length) {
        return (value, filterRowValue) =>
          !filterRowValue ||
          `${value}`.toLowerCase() === `${filterRowValue}`.toLowerCase();
      }
    },
    loading: {
      enabled: true,
      text: "Loading data",
    },
    validation,
    virtualScrolling: {
      enabled: true,
    },
    ...(initialTableProps || {}),
    extendedFilter,
  };
};

export type BatchActionRenderProps<T> = {
  selectedRows: any[];
  isDisabled?: boolean;
  totalDataSelected?: boolean;
  dispatch?: any;
  data?: T[];
};

type BatchAction<T> = {
  label?: string;
  Icon?: React.ReactElement;
  onSubmit?: (selectedItems: T[], totalDataSelected: boolean) => void;
  Render?: (renderProps: BatchActionRenderProps<T>) => React.ReactElement;
  alwaysEnabled?: boolean;
};

type InitialTableProps<T> = Omit<Partial<ITableProps>, "columns"> & {
  columns: CustomColumn<T>[];
};

type Props<T> = {
  fetch?: Function;
  getDataForDownload?: (onlyFiltered?: boolean) => Promise<T[]>;
  onFilterChange?: (newFilters: any) => void;
  onSelectedChange?: (
    selected?: T[],
    rowKeyValue?: any,
    allSelectedKeys?: any[]
  ) => void;
  selectedItems?: any[];
  deleteItem?: (id: string, item: T) => void;
  putItem?: (id: string, item: T, field?: string) => void;
  postItem?: Function;
  dataFromOutside?: T[];
  setOutsideData?: (d: T[] | undefined) => void;
  LeftButton?: (props: ICellTextProps) => React.ReactElement;
  onReorder?: (sourceKeyValue: any, targetKeyValue: any) => void;
  hideAddColumn?: boolean;
  onDataChange?: Function;
  allowBatchActions?: boolean;
  disableRowSelection?: boolean;
  hideTopBar?: boolean;
  allowDownload?: boolean;
  mapForDownload?: (item: T) => any;
  filterForDownload?: (item: T) => boolean;
  entityName?: string;
  additionalBatchActions?: BatchAction<T>[];
  extraFilters?: React.ReactElement[];
  onClearFilters?: () => void;
  initialTableProps?: InitialTableProps<T>;
  containerProps?: Partial<FlexProps>;
  loading?: ILoadingProps;
  paging?: ITableProps["paging"];
  isRowDisabled?: (rowData: T) => boolean;
  extendedFilter?: (rowData: T[]) => T[];
  downloadLabel?: string;
  filteredCount?: number;
  totalCount?: number;
  forceFetchKey?: number;
  hideFilterButton?: boolean;
  onTotalDataSelected?: (totalDataSelected: boolean) => void;
};
export const EntityTable = <T,>(props: Props<T>): React.ReactElement => {
  const {
    fetch,
    getDataForDownload,
    onFilterChange,
    onSelectedChange,
    selectedItems,
    deleteItem,
    putItem,
    postItem,
    dataFromOutside,
    setOutsideData,
    LeftButton,
    hideAddColumn = false,
    onDataChange,
    allowBatchActions = false,
    disableRowSelection = false,
    additionalBatchActions = [],
    extraFilters = [],
    onClearFilters,
    entityName,
    allowDownload,
    mapForDownload,
    filterForDownload,
    loading,
    paging,
    containerProps,
    initialTableProps,
    onReorder,
    isRowDisabled,
    extendedFilter,
    hideTopBar,
    downloadLabel = "Download all",
    totalCount,
    filteredCount,
    forceFetchKey,
    hideFilterButton,
    onTotalDataSelected,
  } = props;
  const extraFiltersDisclosure = useDisclosure();
  const [viewOnlySelected, setViewOnlySelected] = useState(false);
  const [newRowData, setNewRowData] = useState<T>();
  const [_data, setData_] = useState<T[]>();
  const [gettingDataForDownload, setGettingDataForDownload] = useState(false);
  const [totalDataSelected, setTotalDataSelected] = useState(false);
  const [_tableProps, changeTableProps] = useState<ITableProps>(
    tablePropsInit(
      !!LeftButton,
      !!postItem,
      allowBatchActions,
      putItem,
      initialTableProps,
      extendedFilter
    )
  );
  const [filteredData, setFilteredData] = useState<T[]>();
  const [isLoadingFilteredData, setIsLoadingFilteredData] = useState(false);

  const selectedRows =
    selectedItems !== undefined ? selectedItems : _tableProps.selectedRows;

  const tableProps: ITableProps = useMemo(
    () => ({ ..._tableProps, extendedFilter, selectedRows }),
    [_tableProps, extendedFilter, selectedRows]
  );
  const currentPage = useRef(paging?.pageIndex || tableProps.paging?.pageIndex);

  const onFilterChangeDebounced = useMemo(() => {
    return debounce(() => {
      dispatch(updatePageIndex(0));
    }, filterCountdownTime);
  }, []);

  const _changeTableProps: React.Dispatch<React.SetStateAction<ITableProps>> =
    useCallback(
      (newProps) => {
        changeTableProps((oldProps) => ({
          ...(typeof newProps === "function" ? newProps(oldProps) : newProps),
          extendedFilter,
        }));
      },
      [extendedFilter]
    );

  const data = useMemo(
    () => dataFromOutside || _data,
    [_data, dataFromOutside]
  );

  useEffect(() => {
    if (
      getDataForDownload &&
      totalDataSelected &&
      filteredCount &&
      data &&
      filteredCount > data?.length
    ) {
      setIsLoadingFilteredData(true);
      getDataForDownload(true)
        .then((res) => {
          setFilteredData(res);
        })
        .finally(() => {
          setIsLoadingFilteredData(false);
        });
    }
  }, [data, filteredCount, getDataForDownload, totalDataSelected]);

  React.useEffect(() => {
    _changeTableProps((oldTableProps) =>
      kaReducer(oldTableProps, updateData(data || []))
    );
  }, [_changeTableProps, data]);

  useEffect(() => {
    let newColumns: Column[] = [];
    if (LeftButton || allowBatchActions)
      newColumns.push({
        key: "LeftButton",
        style: { width: 74 },
        isEditable: false,
      });
    newColumns = newColumns.concat(
      ...((initialTableProps?.columns || []) as Column[]).map((column) => ({
        style: { width: 150 },
        ...column,
      }))
    );
    if (!hideAddColumn && postItem)
      newColumns.push({
        key: "addColumn",
        style: { width: 74 },
      });
    _changeTableProps((oldTableProps) => {
      return {
        ...oldTableProps,
        columns: newColumns.map((n) => {
          const oldColumn = oldTableProps.columns.find((c) => c.key === n.key);
          return oldColumn ? merge(oldColumn, n) : n;
        }),
      };
    });
  }, [
    LeftButton,
    allowBatchActions,
    initialTableProps,
    hideAddColumn,
    postItem,
    _changeTableProps,
  ]);

  const shouldAskToSelectTotal =
    !!data?.length &&
    (selectedRows?.length ?? 0) >= data.length &&
    filteredCount &&
    data.length < filteredCount;

  const clearSelection = () => {
    dispatch(deselectAllRows());
    setTotalDataSelected(false);
    onTotalDataSelected?.(false);
    if (onSelectedChange)
      onSelectedChange(
        getSelectedData({
          ...tableProps,
          selectedRows: [],
        })
      );
  };

  useEffect(() => {
    dispatch(setSingleAction(loadData()));
  }, [forceFetchKey]);

  const dispatch: DispatchFunc = async (action) => {
    _changeTableProps((oldTableProps) => kaReducer(oldTableProps, action));

    if (deleteItem && action.type === ActionType.DeleteRow) {
      const _action = action as ReturnType<typeof deleteRow>;
      dispatch(showLoading());
      try {
        const item = data?.find(
          (searchItem) =>
            searchItem[(tableProps.rowKeyField || "id") as keyof T] ===
            _action.rowKeyValue
        );
        if (item) await deleteItem(_action.rowKeyValue, item);
      } catch (error) {
        console.log(error);
      }
      dispatch(hideLoading());
      if (!dataFromOutside) dispatch(setSingleAction(loadData()));
    } else if (putItem && action.type === ActionType.UpdateCellValue) {
      const _action = action as ReturnType<typeof updateCellValue>;
      dispatch(showLoading());
      const item = data?.find(
        (searchItem) =>
          searchItem[(tableProps.rowKeyField || "id") as keyof T] ===
          _action.rowKeyValue
      );
      if (item) {
        delete item["error" as keyof T];
        try {
          await putItem(
            _action.rowKeyValue,
            {
              ...(item || {}),
              [_action.columnKey]: _action.value,
            } as T,
            _action.columnKey
          );
        } catch (error) {
          console.log(error);
        }
      }
      dispatch(hideLoading());
      if (!dataFromOutside) dispatch(setSingleAction(loadData()));
    } else if (putItem && action.type === ActionType.UpdateRow) {
      const _action = action as ReturnType<typeof updateRow>;
      const item = data?.find(
        (searchItem) =>
          searchItem[(tableProps.rowKeyField || "id") as keyof T] ===
          _action.rowData[tableProps.rowKeyField || "id"]
      );
      if (item) {
        delete item["error" as keyof T];
        try {
          await putItem(_action.rowData[tableProps.rowKeyField || "id"], {
            ...(item || {}),
            ...(_action.rowData || {}),
          } as T);
        } catch (error) {
          console.log(error);
        }
      }
      if (!dataFromOutside) dispatch(setSingleAction(loadData()));
    } else if (action.type === ActionType.UpdateEditorValue) {
      const _action = action as ReturnType<typeof updateEditorValue>;
      if (Object.keys(_action.rowKeyValue).length === 0) {
        setNewRowData((oldValue) => ({
          ...((oldValue || {}) as T),
          [_action.columnKey]: _action.value,
        }));
      }
    } else if (postItem && action.type === ActionType.SaveNewRow) {
      const _action = action as ReturnType<typeof saveNewRow>;
      if (newRowData && _action.validate) {
        for (let i = 0; i < tableProps.columns.length; i++) {
          const column = tableProps.columns[i];

          if (
            validation({
              value: newRowData[column.key as keyof T],
              column,
              rowData: 0,
            })
          )
            return null;
        }
      }
      dispatch(showLoading());
      try {
        await postItem(newRowData);
      } catch (error) {
        console.log(error);
      }
      setNewRowData(undefined);
      dispatch(hideLoading());
      dispatch(hideNewRow());
      if (!dataFromOutside) dispatch(setSingleAction(loadData()));
    } else if (action.type === ActionType.LoadData) {
      dispatch(showLoading());
      if (fetch) {
        try {
          const result = await fetch(currentPage.current);
          (setOutsideData || setData_)(result);
        } catch (e) {
          console.log(e);
        }
      }
      dispatch(hideLoading());
    } else if (action.type === ActionType.ReorderRows) {
      if (onReorder) onReorder(action.rowKeyValue, action.targetRowKeyValue);
    } else if (action.type === ActionType.UpdatePageIndex) {
      currentPage.current = action.pageIndex;
      if (!dataFromOutside) dispatch(setSingleAction(loadData()));
    } else if (action.type === ActionType.UpdateFilterRowValue) {
      if (onFilterChange)
        onFilterChange(
          tableProps.columns?.reduce((acc, column) => {
            if (column.key === action.columnKey)
              return { ...acc, [column.key]: action.filterRowValue };
            return { ...acc, [column.key]: column.filterRowValue };
          }, {})
        );
      if (data && totalCount && data.length < totalCount) {
        clearSelection();
        onFilterChangeDebounced();
      }
    } else if (action.type === ActionType.ClearAllFilters) {
      if (onFilterChange) onFilterChange({});
      if (data && totalCount && data.length < totalCount) {
        clearSelection();
        dispatch(updatePageIndex(0));
      }
    }
  };

  React.useEffect(() => {
    if (onDataChange) {
      onDataChange(getData(tableProps));
    }
  }, [onDataChange, tableProps]);

  const SelectItemCheckbox = (props: ICellTextProps) => {
    const { rowKeyValue, isSelectedRow, dispatch, rowData } = props;
    return (
      <IconButton
        aria-label="checkbox icon button"
        variant="link"
        size="lg"
        colorScheme={isSelectedRow ? "blue" : undefined}
        icon={
          isSelectedRow ? <MdCheckBox /> : <MdOutlineCheckBoxOutlineBlank />
        }
        isDisabled={isRowDisabled?.(rowData)}
        onClick={async (event) => {
          const isChecked = isSelectedRow;
          if (!isChecked) {
            await dispatch(selectRow(rowKeyValue));
          } else {
            if (totalDataSelected) {
              clearSelection();
            } else {
              await dispatch(deselectRow(rowKeyValue));
            }
          }
          if (onSelectedChange && !(isChecked && totalDataSelected)) {
            const newKeys = !isChecked
              ? [...(selectedRows || []), rowKeyValue]
              : (selectedRows || []).filter((s) => s !== rowKeyValue);
            onSelectedChange(
              getSelectedData({
                ...tableProps,
                selectedRows: newKeys,
              }),
              rowKeyValue,
              newKeys
            );
          }
        }}
      />
    );
  };

  const allChecked = useMemo(
    () => areAllFilteredRowsSelected(tableProps),
    [tableProps]
  );
  const isIndeterminate = !!selectedRows?.length && !allChecked;

  const BatchDeletePopover = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <Popover
        isLazy
        isOpen={isOpen && !!selectedRows?.length}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
      >
        <PopoverTrigger>
          <Button
            leftIcon={<IoTrashOutline />}
            variant="link"
            aria-label={"Batch delete items"}
            isDisabled={!selectedRows?.length}
          >
            Delete
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody>
            <Text>
              Confirm deletion of {selectedRows?.length} items{" "}
              <Button
                colorScheme="red"
                onClick={async () => {
                  const toDelete = getSelectedData(tableProps);
                  dispatch(showLoading());
                  if (deleteItem) {
                    try {
                      await Promise.all(
                        toDelete.map((s: T) =>
                          deleteItem(
                            `${s[tableProps.rowKeyField as keyof T]}`,
                            s
                          )
                        )
                      );

                      toast.success("Deleted items");
                      clearSelection();
                    } catch (error) {
                      console.log(error);
                    }
                  }
                  dispatch(hideLoading());
                  dispatch(setSingleAction(loadData()));
                  setIsOpen(false);
                }}
              >
                Delete
              </Button>
            </Text>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };

  const downloadData = async (onlyFiltered?: boolean, _data?: T[]) => {
    let dataToSave = _data || data || [];
    if (onlyFiltered && !_data?.length) {
      dataToSave = filterAndSearchData(tableProps);
    }
    if (filterForDownload && !onlyFiltered) {
      dataToSave = dataToSave.filter(filterForDownload);
    }
    dataToSave = dataToSave.map<any>((dataRow) =>
      tableProps.columns.reduce(
        (acc, column: CustomColumn<T>) => ({
          ...acc,
          [column.key]: `${
            column.format
              ? column.format(dataRow[column.key as keyof T])
              : dataRow[column.key as keyof T] || ""
          }`,
        }),
        {}
      )
    );
    if (mapForDownload) {
      dataToSave = dataToSave.map(mapForDownload);
    }
    var wb = XLSX.utils.book_new();

    const storesSheet = XLSX.utils.json_to_sheet(dataToSave);
    XLSX.utils.book_append_sheet(
      wb,
      storesSheet,
      pluralize(entityName || "data")
    );
    XLSX.writeFile(wb, (entityName || "data") + ".xlsx");
  };

  const filteredKeys = useMemo(() => {
    let result = filterAndSearchData(tableProps);
    return result
      .map((d) => d[tableProps.rowKeyField || "id"])
      .filter((r) => !viewOnlySelected || selectedRows?.includes(r));
  }, [selectedRows, tableProps, viewOnlySelected]);

  const getBatchActions = (disabled?: boolean, filteredData?: T[]) => {
    const selectedData = getSelectedData(tableProps);
    return additionalBatchActions?.map((batchAction) =>
      batchAction.Render ? (
        <batchAction.Render
          selectedRows={selectedData}
          isDisabled={
            !batchAction.alwaysEnabled && (!selectedRows?.length || !!disabled)
          }
          dispatch={dispatch}
          data={totalDataSelected ? filteredData || [] : data}
          totalDataSelected={totalDataSelected}
        />
      ) : (
        <Button
          variant="link"
          leftIcon={batchAction.Icon}
          size="md"
          onClick={() =>
            batchAction.onSubmit?.(selectedData, totalDataSelected)
          }
          isDisabled={
            !batchAction.alwaysEnabled && (!selectedRows?.length || !!disabled)
          }
        >
          {batchAction.label}
        </Button>
      )
    );
  };

  return (
    <Flex
      direction="column"
      w="100%"
      h="440px"
      rounded="lg"
      bg="white"
      overflow="hidden"
      border={`1px solid #9CABB3`}
      className="custom-theme"
      {...(containerProps || {})}
    >
      {!hideTopBar && (
        <HStack
          spacing={3}
          pl={`20px`}
          pr="20px"
          paddingY="8px"
          h="40px"
          bg={
            ((dathicChakraTheme.colors?.blue || {}) as Colors)["400"] as string
          }
          zIndex={9}
        >
          {allowBatchActions && (
            <Checkbox
              isChecked={allChecked}
              isIndeterminate={isIndeterminate}
              bg="white"
              colorScheme="lightBlue"
              isDisabled={!!isLoadingFilteredData}
              onChange={async (event) => {
                setTotalDataSelected(false);
                const isChecked = event.target.checked && !isIndeterminate;
                if (isChecked) {
                  await dispatch(selectAllFilteredRows());
                } else {
                  if (totalDataSelected) {
                    clearSelection();
                  } else {
                    await dispatch(deselectAllFilteredRows());
                  }
                }
                if (isRowDisabled) {
                  await Promise.all(
                    data
                      ?.filter((d) => isRowDisabled(d))
                      .map((d) =>
                        dispatch(
                          deselectRow(d[tableProps.rowKeyField as keyof T])
                        )
                      ) || []
                  );
                }
                if (onSelectedChange) {
                  const newKeys = isChecked
                    ? Array.from(
                        new Set([
                          ...(selectedRows || []),
                          ...(filteredKeys || []),
                        ])
                      )
                    : (selectedRows || []).filter(
                        (s) => !(filteredKeys || []).includes(s)
                      );
                  onSelectedChange(
                    getSelectedData({
                      ...tableProps,
                      selectedRows: newKeys,
                    }).filter((d) => !isRowDisabled?.(d)),
                    undefined,
                    newKeys
                  );
                }
              }}
            ></Checkbox>
          )}
          {allowBatchActions &&
            (selectedRows?.length || viewOnlySelected) &&
            !shouldAskToSelectTotal && (
              <Button
                variant="link"
                colorScheme="lightBlue"
                onClick={() => setViewOnlySelected((oldValue) => !oldValue)}
              >
                {viewOnlySelected ? "" : "View"} {selectedRows?.length} selected
                {viewOnlySelected ? ": Show all" : ""}
              </Button>
            )}
          {allowBatchActions &&
            getBatchActions(isLoadingFilteredData, filteredData)}
          {allowBatchActions && deleteItem && <BatchDeletePopover />}
          {(tableProps.loading?.enabled || !!isLoadingFilteredData) && (
            <Spinner size="sm" />
          )}
          <Spacer />
          {extraFiltersDisclosure.isOpen && <>{extraFilters}</>}
          {!hideFilterButton && (
            <Button
              variant="link"
              {...extraFiltersDisclosure.getButtonProps({
                onClick: () => {
                  _changeTableProps((oldProps) => ({
                    ...oldProps,
                    filteringMode:
                      oldProps.filteringMode === FilteringMode.FilterRow
                        ? FilteringMode.None
                        : FilteringMode.FilterRow,
                  }));
                  if (tableProps.filteringMode === FilteringMode.FilterRow) {
                    dispatch(clearAllFilters());
                    onClearFilters?.();
                  }
                },
              })}
            >
              {tableProps.filteringMode === FilteringMode.FilterRow
                ? "Clear filters"
                : "Filter"}
            </Button>
          )}
        </HStack>
      )}
      {!!shouldAskToSelectTotal && (
        <HStack bg={"#eef1f5"} p={3} w="100%" justifyContent={"center"}>
          {!totalDataSelected && (
            <>
              <Text>
                All {Number(data?.length ?? 0).toLocaleString()} items on this
                page are selected.
              </Text>
              <Button
                variant={"link"}
                onClick={() => {
                  setTotalDataSelected(true);
                  onTotalDataSelected?.(true);
                }}
              >
                Select all {Number(totalCount).toLocaleString()} items
              </Button>
            </>
          )}
          {!!totalDataSelected && (
            <>
              <Text>
                All {Number(totalCount).toLocaleString()} items are selected.
              </Text>
              <Button
                variant={"link"}
                onClick={async () => {
                  setTotalDataSelected(false);
                  onTotalDataSelected?.(false);
                  clearSelection();
                }}
              >
                Clear selection
              </Button>
            </>
          )}
        </HStack>
      )}
      <Box w="100%" flex={1}>
        <AutoSizer>
          {({ height: innerHeight, width: innerWidth }) => (
            <Box h={`${innerHeight}px`} w={`${innerWidth}px`}>
              <Table
                {...tableProps} // (2) ka-table UI is rendered according to props
                extendedFilter={extendedFilter}
                loading={loading || tableProps.loading}
                paging={paging || tableProps.paging}
                dispatch={dispatch} // dispatch is required for obtain new actions from the UI
                selectedRows={selectedRows}
                search={({ searchText: searchTextValue, rowData }) => {
                  if (searchTextValue === "selected") {
                    return !!selectedRows?.includes(
                      rowData[tableProps.rowKeyField]
                    );
                  }
                  return JSON.stringify(rowData || "").includes(
                    searchTextValue
                  );
                }}
                searchText={viewOnlySelected ? "selected" : undefined}
                childComponents={{
                  headCellContent: {
                    elementAttributes: () => {
                      return {
                        style: {
                          display: "flex",
                          alignItems: "center",
                        },
                      };
                    },
                    content: (props) => {
                      const { column } = props;
                      if (
                        !column.sortDirection &&
                        props.sortingMode !== SortingMode.None &&
                        column.isSortable !== false &&
                        column.key !== "LeftButton"
                      ) {
                        return (
                          <>
                            <span>{column.title}</span>
                            <RiArrowUpDownFill style={{ marginLeft: "3px" }} />
                          </>
                        );
                      }
                    },
                  },
                  tableWrapper: {
                    elementAttributes: () => {
                      return {
                        style: {
                          height: `${
                            innerHeight - (tableProps.paging?.enabled ? 51 : 0)
                          }px`,
                          width: `${innerWidth}px`,
                        },
                      };
                    },
                  },
                  cellEditor: {
                    content: (props) => {
                      const column: CustomColumn<T> = props.column;
                      if (props.column.key === "addColumn") {
                        return <SaveButton {...props} />;
                      }
                      if (typeof column.Render === "function") {
                        return (
                          <column.Render {...props} data={data} isEditing />
                        );
                      }
                      if (column.filterOptions?.length || -1 > 0) {
                        return <CustomLookupSelect {...props} shouldUpdate />;
                      }
                    },
                  },
                  headCell: {
                    content: (props) => {
                      if (props.column.key === "addColumn") {
                        return postItem ? (
                          <AddButton {...props} disabled={!postItem} />
                        ) : (
                          <></>
                        );
                      }
                      const column: CustomColumn<T> = props.column;
                      if (column.info) {
                        return (
                          <Text position="relative">
                            {props.column.title}
                            <InfoPopover
                              iconProps={{
                                ml: 3,
                                position: "absolute",
                                top: -1,
                                right: -2,
                              }}
                              info={column.info}
                              popoverProps={{ gutter: 30 }}
                              triggerContent={undefined}
                            />
                          </Text>
                        );
                      }
                    },
                    elementAttributes: (props) => {
                      if (props.column.key === "addColumn") {
                        return {
                          style: {
                            ...props.column.style,
                            position: "sticky",
                            right: 0,
                            zIndex: 2,
                          },
                        };
                      }
                      if (props.column.key === "LeftButton") {
                        return {
                          style: {
                            ...props.column.style,
                            position: "sticky",
                            left: 0,
                            zIndex: 2,
                          },
                        };
                      }
                      return {
                        style: {
                          ...props.column.style,
                          zIndex: 1,
                        },
                      };
                    },
                  },
                  cell: {
                    elementAttributes: (props) => {
                      if (props.column.key === "addColumn") {
                        return {
                          style: {
                            ...props.column.style,
                            position: "sticky",
                            right: 0,
                          },
                        };
                      }
                      if (props.column.key === "LeftButton") {
                        return {
                          style: {
                            ...props.column.style,
                            position: "sticky",
                            left: 0,
                            padding: "8px 10px",
                          },
                        };
                      }
                    },
                  },
                  cellText: {
                    content: (props) => {
                      const column: CustomColumn<T> = props.column;
                      if (props.column.key === "addColumn") {
                        return deleteItem ? (
                          <DeleteRow {...props} disabled={!deleteItem} />
                        ) : (
                          <></>
                        );
                      }
                      if (props.column.key === "LeftButton") {
                        return (
                          <HStack>
                            {allowBatchActions && !disableRowSelection && (
                              <SelectItemCheckbox {...props} />
                            )}
                            {LeftButton && <LeftButton {...props} />}
                          </HStack>
                        );
                      }
                      if (typeof column.Render === "function") {
                        return <column.Render {...props} data={data} />;
                      }
                      return (
                        <Tooltip
                          openDelay={500}
                          label={
                            props.format?.(props) ||
                            props.value ||
                            column.default
                          }
                        >
                          <Text
                            {...props}
                            noOfLines={3}
                            {...(putItem
                              ? { color: "gray", fontStyle: "italic" }
                              : {})}
                          >
                            {props.format?.(props) ||
                              props.value ||
                              column.default ||
                              (putItem ? "Click to edit" : "")}
                          </Text>
                        </Tooltip>
                      );
                    },
                    elementAttributes: (props) => {
                      if (props.column.key === "LeftButton") {
                        return {
                          style: {
                            background: "white",
                          },
                        };
                      }
                      return {
                        style: { lineHeight: 1.4, minHeight: 24 },
                      };
                    },
                  },
                  noDataRow: {
                    content: () => `No ${pluralize(entityName || "Data")}`,
                  },
                  filterRowCell: {
                    content: (props) => {
                      const column: CustomColumn<T> = props.column;
                      if (
                        column.key === "addColumn" ||
                        column.key === "LeftButton" ||
                        column.hideFilter
                      ) {
                        return <div></div>;
                      }
                      if (column.FilterRender) {
                        return column.FilterRender(props);
                      }
                      if (column.filterOptions?.length || -1 > 0) {
                        return (
                          <CustomLookupSelect
                            editingMode={EditingMode.None}
                            field={""}
                            isDetailsRowShown={false}
                            isSelectedRow={false}
                            rowData={undefined}
                            rowKeyField={""}
                            rowKeyValue={undefined}
                            value={undefined}
                            selectedRows={[]}
                            shouldUpdate={false}
                            {...props}
                          />
                        );
                      } else if (props.column.dataType === DataType.Number) {
                        return <FilterEditorWithOperators {...props} />;
                      }
                      return <FilterEditor {...props} />;
                    },
                    elementAttributes: (props) => {
                      if (props.column.key === "addColumn") {
                        return {
                          style: {
                            ...props.column.style,
                            position: "sticky",
                            right: 0,
                          },
                        };
                      }
                      if (props.column.key === "LeftButton") {
                        return {
                          style: {
                            ...props.column.style,
                            position: "sticky",
                            left: 0,
                          },
                        };
                      }
                    },
                  },
                }}
              />
            </Box>
          )}
        </AutoSizer>
      </Box>
      <HStack paddingX={5} alignSelf="flex-start" zIndex={9}>
        <Text>
          {filteredKeys?.length || 0} / {(totalCount ?? data?.length) || 0}{" "}
          {pluralize(entityName || "")}
        </Text>
        {allowDownload && (
          <Button
            variant="link"
            onClick={async () => {
              let toDownload = data || [];
              if (
                getDataForDownload &&
                totalCount &&
                (data?.length ?? 0) < totalCount
              ) {
                setGettingDataForDownload(true);
                toDownload = await getDataForDownload();
                setGettingDataForDownload(false);
              }
              downloadData(false, toDownload);
            }}
            isLoading={gettingDataForDownload}
            isDisabled={!data?.length}
          >
            {downloadLabel}
          </Button>
        )}
        {allowDownload && (
          <Button
            variant="link"
            onClick={async () => {
              if (
                getDataForDownload &&
                filteredCount &&
                (data?.length ?? 0) < filteredCount
              ) {
                setGettingDataForDownload(true);
                const _toDownload = await getDataForDownload(true);
                setGettingDataForDownload(false);
                downloadData(true, _toDownload);
              } else {
                downloadData(true, data || []);
              }
            }}
            isLoading={gettingDataForDownload}
            isDisabled={!data?.length}
          >
            Download filtered{" "}
            {`(${(filteredCount ?? filteredKeys?.length) || 0})`}
          </Button>
        )}
      </HStack>
    </Flex>
  );
};
